import { FrownOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "@tanstack/react-router";
import { Button, Flex, Result, Typography } from "antd";
import { FC, useEffect, useState } from "react";

type Props = {
  timeoutSec?: number;
  title?: string;
  description?: string;
}

const NotFoundOrForbidden: FC<Props> = (props) => {
  const { timeoutSec = 7 } = props;
  const [secondsRemaining, setSecondsRemaining] = useState(timeoutSec);
  const navigate = useNavigate();

  useEffect(() => {
    const countdown = setInterval(() => {
      setSecondsRemaining((prev) => prev - 1);
    }, 1_000);

    const redirectTimeout = setTimeout(() => {
      navigate({ to: "/" });
    }, 7 * 1_000);

    return () => {
      clearInterval(countdown);
      clearTimeout(redirectTimeout);
    };
  }, [navigate]);


  return (
    <Flex
      justify="center"
      style={{
        paddingTop: 50,
      }}
    >
      <Result
        icon={<FrownOutlined />}
        title="The requested resource cannot be found."
        subTitle="The resource you are looking for (or one of its dependencies) could have been removed, had its name changed, or is temporarily unavailable. Please review the following URL and make sure that it is spelled correctly."
        extra={
          <>
            <Typography.Paragraph>
              You will be redirected in {secondsRemaining} seconds
            </Typography.Paragraph>
            <Link to="/">
              <Button type="primary" key="console">
                Back Home
              </Button>
            </Link>
          </>
        }
        style={{
          maxWidth: 700
        }}
      />
    </Flex>
  );
};

export default NotFoundOrForbidden;
