import { FC, memo } from "react";
import { EditableTableFormItemComponent } from "./lib";
import EmptyCell from "components/DataTable/SimpleTable/TableCell/Empty";

const Formula: FC<EditableTableFormItemComponent> = () => {
  return (
    <EmptyCell />
  );
};

export default memo(Formula);
