import { EditableTableFormItemComponent } from "./lib";
import { FC, memo } from "react";
import { TableInput } from "components/DataTable/SimpleTable/FormItems/styled";

const MultiLineText: FC<EditableTableFormItemComponent<string>> = (props) => {
  const { value, onChange } = props;
  const formHandlers = { defaultValue: value, onBlur: onChange };

  return (
    <TableInput
      size="small"
      placeholder="Empty"
      {...formHandlers}
    />
  );
};

export default memo(MultiLineText);
