import WebViewer, { WebViewerOptions } from "@pdftron/webviewer";
import configuration from "configuration";
import { FC, HTMLAttributes, useEffect, useRef } from "react";
import { ApryseWebViewerPath } from "./lib";

type Props = {
  containerProps?: HTMLAttributes<HTMLDivElement>;
  webViewerOptions?: Partial<WebViewerOptions>;
}

const ApryseViewer: FC<Props> = (props) => {
  const { webViewerOptions, containerProps } = props;
  const viewer = useRef<HTMLDivElement>(null);

  // @link https://apryse.com/blog/two-instances-of-webviewer-warning-and-fix
  const initialized = useRef<boolean>(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      WebViewer(
        {
          ...webViewerOptions,
          path: ApryseWebViewerPath,
          // css: ApryseStylesPath,
          licenseKey: configuration.apryse.licenseKey,
        },
        viewer.current!,
      ).then((instance) => {
        const { documentViewer } = instance.Core;
        console.log("documentViewer", documentViewer);
        // you can now call WebViewer APIs here...
      });
    }
  }, []);

  return (
    <div
      className="webviewer"
      ref={viewer}
      style={{ height: "100%", width: "100%" }}
      {...containerProps}
    />
  );
};

export default ApryseViewer;
