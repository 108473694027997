import { useWatch } from "antd/es/form/Form";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { NamePath } from "antd/es/form/interface";
import React, { FC } from "react";
import { IconPickerFormItemGrid } from "components/FormColorIconPicker/styled.ts";
import { Card, theme } from "antd";

type IconPickerFormItemProps = {
  name: NamePath;
  icons: React.FC<{ style?: React.CSSProperties }>[];
};

const IconPickerFormItem: FC<IconPickerFormItemProps> = (props) => {
  const { token } = theme.useToken();
  
  const { name, icons } = props;
  const form = useFormInstance();

  const selectedIcon = useWatch(name, { form, preserve: true });

  const handleIconClick = (iconName: string) => {
    form.setFieldValue(name, iconName);
  };

  return (
    <IconPickerFormItemGrid>
      {icons.map((IconComponent) => (
        <Card
          key={IconComponent.displayName}
          onClick={() => handleIconClick(IconComponent.displayName!)}
          style={{
            ...(selectedIcon === IconComponent.displayName && { borderColor: token.colorPrimary }),
          }}
          styles={{
            body: {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 24,
              height: 24,
              padding: 0,
              cursor: "pointer",
            },
          }}
        >
          <IconComponent
            style={{
              fontSize: 16,
              color: "var(--ant-color-primary)"
            }}
          />
        </Card>
      ))}
    </IconPickerFormItemGrid>
  );
};

export default IconPickerFormItem;
