import styled from "@emotion/styled";
import { Flex } from "antd";
import { css } from "antd-style";
import { UseBreakpointsState } from "lib/hooks/useBreakpoints";

export const DataListWrap = styled.div`
`;

export const DataListGrid = styled.div<{ breakpoints?: UseBreakpointsState, oneCol?: boolean }>`
  display: grid;
  grid-template-columns: minmax(0, 150px) minmax(0, 1fr);
  grid-column-gap: 20px;
  ${({ breakpoints }) => breakpoints?.isMobilePortrait && css`
    grid-template-columns: 1fr;
  `}
  ${({ oneCol }) => oneCol && css`
    grid-template-columns: 1fr;
  `}
`;

export const DataListPropertyValue = styled(Flex)`
  display: flex;
  padding: 0 8px;
  border-radius: 7px;
  transition: all 200ms ease;
  border: 1px solid transparent;
`;
