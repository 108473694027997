import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { FC, PropsWithChildren } from "react";
import { BaseItem } from "./lib";

/** @deprecated */
const SortableItem: FC<PropsWithChildren<BaseItem>> = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={{
        transform: CSS.Transform.toString(transform),
        transition: transition
      }}
    >
      {props.children}
    </div>
  );
};

export default SortableItem;
