import {
  CloseOutlined,
  HolderOutlined,
  PlusCircleOutlined,
  PlusOutlined
} from "@ant-design/icons";
import { closestCenter, DndContext, DragEndEvent, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, rectSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import { Button, Flex, Form, Input, message, Modal, Select, Tooltip, Typography } from "antd";
import PropertyTitle from "components/DataTable/SimpleTable/PropertyTitle";
import FormColorIconPicker from "components/FormColorIconPicker";
import FormDebugger from "components/FormDebugger";
import { MouseSensor, NoDndHandler, TouchSensor } from "components/SortableList/lib";
import SortableItem from "components/SortableList/SortableItem";
import { useModalForm } from "lib/hooks/antd-sunflower";
import { useTr } from "lib/i18n";
import { getRandomAntPresetColorKey } from "lib/utils/colors";
import { getTableColumnIcon } from "lib/utils/table";
import { cloneElement, FC, ReactElement, ReactNode } from "react";
import { handleApiFormRequest } from "redux/helpers";
import { FormWrap } from "styles/common";
import { IDataColumn } from "types/data-component";
import { ColumnManagerCustomItems, ColumnManagerDateFormatOptions, ColumnManagerTimeFormatOptions } from "./lib";

interface ColumnManagerModalProps {
  onSubmit?: (data: Partial<IDataColumn>) => void;
  initialValues?: Partial<IDataColumn>;
  triggerElement?: ReactNode;
}

type FormProps = Partial<IDataColumn>;

const ColumnManagerModal: FC<ColumnManagerModalProps> = (props) => {
  const { initialValues, onSubmit, triggerElement } = props;
  const { T } = useTr();

  const { showModal, closeModal, form, modalProps } = useModalForm<FormProps>({
    title: initialValues ? "Modify field" : "New field",
    okText: initialValues ? "Save" : "Create",
    centered: true,
  });

  const currentTitle = Form.useWatch("title", form);
  const currentType = Form.useWatch("type", form);
  const currentItem = ColumnManagerCustomItems.find(item => item.type === currentType);
  const iconsEnabled = initialValues?.code === "status";

  const handleOnSubmit = () => {
    form.validateFields()
      .then(values => {
        if (!onSubmit) {
          return;
        }

        if (values.options) {
          const options = values.options.map((opt, index) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
            const { _id, ...rest } = opt as any;
            return {
              ...rest,
              order: index,
            };
          });

          values.options = options;
        }

        handleApiFormRequest(
          () => onSubmit(values),
          () => {
            closeModal();
            form.resetFields();
          }
        );
      })
      .catch(() => {
        message.error(T("pages.MemberInvite.MissingFields"));
      });
  };

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    const prevState = form.getFieldValue("options");

    if (over && active.id !== over?.id) {
      // Find indexes of dragged and target items
      const activeIndex = prevState.findIndex(({ _id }: { _id: string }) => _id === active.id);
      const overIndex = prevState.findIndex(({ _id }: { _id: string }) => _id === over.id);

      // Move items and update the form state
      const nextState = arrayMove(prevState, activeIndex, overIndex) as typeof prevState;
      form.setFieldsValue({ options: nextState });
    }
  };

  return (
    <>
      {triggerElement
        ? cloneElement(triggerElement as ReactElement, { onClick: showModal })
        : <PropertyTitle icon={PlusCircleOutlined} onClick={showModal} pointer />
      }
      <Modal
        {...modalProps}
        onOk={handleOnSubmit}
        okButtonProps={{ disabled: !currentTitle || currentTitle?.trim().length === 0 }}
      >
        <FormWrap>
          <Form
            form={form}
            layout="vertical"
            initialValues={initialValues}
          >

            <Flex vertical gap="small">

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gridColumnGap: 10,
                }}
              >

                <Flex>
                  <Form.Item<FormProps>
                    label="Field title"
                    name="title"
                    rules={[{ required: true, message: "Please enter a field title", whitespace: true }]}
                    style={{ flexGrow: 1 }}
                    noStyle
                  >
                    <Input placeholder="Enter column name" />
                  </Form.Item>
                </Flex>

                <Flex>
                  <Form.Item<FormProps>
                    label="Field type"
                    name="type"
                    rules={[{ required: true, message: "Please select a field type" }]}
                    style={{ flexGrow: 1 }}
                    noStyle
                  >
                    <Select
                      placeholder="Select field type"
                      style={{ width: "100%" }}
                      disabled={!!initialValues}
                      showSearch
                      filterOption={(input, option) => {
                        const foundKeys = String(option?.value).toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        // @ts-expect-error - Type mismatch
                        const foundTitles = String(option?.children?.props?.children[1]).toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        const found = foundKeys || foundTitles;
                        return found;
                      }}
                    >
                      <Select.OptGroup key="custom" label="Custom">
                        {ColumnManagerCustomItems.map(item => {
                          const IconComponent = getTableColumnIcon(item.type);
                          return (
                            <Select.Option key={item.type} value={item.type}>
                              <span style={{ display: "flex", alignItems: "center" }}>
                                <IconComponent />
                                <Typography.Text style={{ marginLeft: "8px" }}>{item.title}</Typography.Text>
                              </span>
                            </Select.Option>
                          );
                        })}
                      </Select.OptGroup>
                      <Select.OptGroup key="default" label="Default">
                      </Select.OptGroup>
                      <Select.OptGroup key="system" label="System">
                      </Select.OptGroup>
                    </Select>
                  </Form.Item>
                </Flex>
              </div>

              <Typography.Text type="secondary">
                {currentItem?.description}
              </Typography.Text>

              {currentItem?.optionKeys.includes("precision") &&
                <Flex>
                  <Form.Item<FormProps>
                    name="precision"
                    label="Precision"
                    style={{ flexGrow: 1 }}
                  >
                    <Select
                      options={[
                        { label: "1", value: 0 },
                        { label: "1.0", value: 1 },
                        { label: "1.00", value: 2 },
                        { label: "1.000", value: 3 },
                        { label: "1.0000", value: 4 },
                      ]}
                    />
                  </Form.Item>
                </Flex>
              }

              {currentItem?.optionKeys.includes("currency") &&
                <Flex>
                  <Form.Item<FormProps>
                    name="currency"
                    label="Currency"
                    style={{ flexGrow: 1 }}
                  >
                    <Input />
                  </Form.Item>
                </Flex>
              }

              {currentItem?.optionKeys.includes("dateFormat") &&
                <Form.Item<FormProps>
                  label="Date format"
                  name="dateFormat"
                >
                  <Select
                    options={Object.values(ColumnManagerDateFormatOptions).map((option) => ({
                      label: option.label,
                      value: option.value,
                    }))}
                  />
                </Form.Item>
              }

              {currentItem?.optionKeys.includes("timeFormat") &&
                <Form.Item<FormProps>
                  label="Time format"
                  name="timeFormat"
                >
                  <Select
                    options={Object.values(ColumnManagerTimeFormatOptions).map((option) => ({
                      label: option.label,
                      value: option.value,
                    }))}
                  />
                </Form.Item>
              }

              {currentItem?.optionKeys.includes("options") &&
                <Form.Item label="Options">
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                  >
                    <SortableContext
                      items={form.getFieldValue("options") || []}
                      strategy={rectSortingStrategy}
                    >
                      <Form.List name="options">
                        {(fields, { add, remove }) => (
                          <>
                            <Flex vertical gap="small">
                              <SortableContext
                                items={form.getFieldValue("options")?.map(({ _id }: { _id: string }) => _id) || []}
                                strategy={rectSortingStrategy}
                              >
                                {fields.map((field, idx) => {
                                  const currentField = form.getFieldValue(["options", field.name]);
                                  const _id = currentField?._id || crypto.randomUUID();

                                  return (
                                    <SortableItem key={field.key} id={_id}>
                                      <Flex align="center" gap="small">

                                        {/*Dragger*/}
                                        <HolderOutlined style={{ cursor: "grab" }} />

                                        <Flex
                                          align="center"
                                          gap="small"
                                          style={{ flexGrow: 1 }}
                                          {...NoDndHandler}
                                        >

                                          <Form.Item
                                            name={[field.name, "_id"]}
                                            initialValue={_id}
                                            hidden
                                          />

                                          <FormColorIconPicker
                                            colorKey={["options", idx, "color"]}
                                            iconKey={iconsEnabled ? ["options", idx, "icon"] : undefined}
                                            codeKey={initialValues?.enableCodes ? ["options", idx, "abbreviation"] : undefined}
                                          />

                                          <Flex style={{ flexGrow: 1 }}>
                                            <Form.Item
                                              {...field}
                                              name={[field.name, "title"]}
                                              fieldKey={[field.key, "title"]}
                                              rules={[{ required: true, message: "Please enter the option title" }]}
                                              style={{ flexGrow: 1 }}
                                              noStyle
                                            >
                                              <Input
                                                placeholder="Type option title..."
                                                size="small"
                                                variant="borderless"
                                              />
                                            </Form.Item>
                                          </Flex>

                                          <Tooltip title="Remove item">
                                            <Button
                                              icon={<CloseOutlined style={{ transform: "scale(0.7)" }} />}
                                              onClick={() => remove(field.name)}
                                              type="text"
                                              size="small"
                                            />
                                          </Tooltip>
                                        </Flex>
                                      </Flex>
                                    </SortableItem>
                                  );
                                })}
                              </SortableContext>
                              <Flex>
                                <Button
                                  onClick={() => add({
                                    _id: crypto.randomUUID(),
                                    title: "",
                                    color: getRandomAntPresetColorKey()
                                  })}
                                  icon={<PlusOutlined />}
                                  size="small"
                                  type="text"
                                >
                                  Add option
                                </Button>
                              </Flex>
                            </Flex>
                          </>
                        )}
                      </Form.List>
                    </SortableContext>
                  </DndContext>
                </Form.Item>
              }
              <FormDebugger form={form} />

            </Flex>

          </Form>
        </FormWrap>
      </Modal>
    </>
  );
};

export default ColumnManagerModal;
