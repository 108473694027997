import { SelectProps } from "antd";
import { IDataColumn } from "types/data-component";

// These are inherited from Form.Item
// source: https://ant.design/components/form#why-formitem-not-update-value-when-children-is-nest
export interface FormItemAntProps<ValueType = unknown> {
  id?: string;
  checked?: boolean;
  value?: ValueType;
  onChange?: (args: unknown) => void;
}

export type EditableTableFormItemComponent<ValueType = unknown> = FormItemAntProps<ValueType> & {
  editable: boolean;
  column: IDataColumn;
  validateField?: boolean;
}

export const SelectFormItemProps: SelectProps = {
  size: "small",
  placeholder: "Empty",
  variant: "borderless",
  dropdownStyle: { minWidth: 150 },
};
