import { getRouteApi, Outlet } from "@tanstack/react-router";
import { Flex, Layout, theme } from "antd";
import LoadingSkeleton from "components/LoadingSkeleton";
import NotFoundOrForbidden from "components/NotFoundOrForbidden";
import { ListProvider } from "lib/context/ListContext";
import { useSpaceContext } from "lib/context/SpaceContext";
import { FC } from "react";
import { useGetListDetailedQuery } from "redux/services/listsApi";

export const ListLayout: FC = () => {
  const routeApi = getRouteApi("/s/$id/l/$listId");
  const { listId }: { listId: string } = routeApi.useParams();
  const { token } = theme.useToken();

  const { data: space } = useSpaceContext();
  const { data: list, isLoading, isSuccess, error } = useGetListDetailedQuery({ spaceId: space.uid, listId: listId });

  return (
    <>
      {isLoading &&
        <LoadingSkeleton />
      }
      {error &&
        <NotFoundOrForbidden />
      }
      {isSuccess && list &&
        <ListProvider data={list}>
          <Layout style={{ backgroundColor: token.colorBgBase }}>
            <Flex vertical style={{ overflowX: "scroll", flexGrow: 1 }}>
              <Outlet />
            </Flex>
          </Layout>
        </ListProvider>
      }
    </>
  );
};
