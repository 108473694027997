import { blue, purple, cyan, green, magenta, red, orange, yellow, volcano, geekblue, lime, gold } from "@ant-design/colors";
import { Palette } from "@ant-design/colors/es/types";
import { PresetColorKey } from "antd/es/theme/internal";

export type ColorsPalette = Partial<Record<PresetColorKey, Palette>>;

export const AntPresetColors: Partial<Record<PresetColorKey, Palette>> = {
  magenta: magenta,
  red: red,
  volcano: volcano,
  orange: orange,
  gold: gold,
  yellow: yellow,
  lime: lime,
  green: green,
  cyan: cyan,
  blue: blue,
  geekblue: geekblue,
  purple: purple,
};

const fallBackPalette: Palette = AntPresetColors.blue!;
export const getHexFromAntPresetColor = (preset: PresetColorKey | string) => AntPresetColors[preset as PresetColorKey]?.primary ?? fallBackPalette.primary;

export const getAntPresetFromHex = (hex: string) => Object.keys(AntPresetColors).find((key) => {
  const palette = AntPresetColors[key as PresetColorKey];
  return palette?.primary === hex;
}) as PresetColorKey;

export const getRandomAntPresetColorKey = (): string => {
  const keys = Object.keys(AntPresetColors) as PresetColorKey[];
  const randomIndex = Math.floor(Math.random() * keys.length);
  return keys[randomIndex];
};

export const Colors = [
  "#d6f0fb",
  "#ccf5e9",
  "#d8f6c8",
  "#fcebbc",
  "#f9e3d7",
  "#f9dde4",
  "#fadbf5",
  "#ebe2fc",
  "#8dceef",
  "#8ddac5",
  "#a4dd91",
  "#f8d77e",
  "#f3ad87",
  "#f1a2b7",
  "#eda2dd",
  "#c8b1fa",
];

export const getRandomColor = () => Colors[Math.floor(Math.random() * Colors.length)];

