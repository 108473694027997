import { IRecord } from "types/record";
import { rootApi } from "./root";
import { JSendResponse } from "types/jsend";

export const recordsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getRecords: builder.query<Array<IRecord>, { spaceId: string, listId: string }>({
      query: ({ spaceId, listId }) => ({
        url: `v2/spaces/${spaceId}/lists/${listId}/records`,
        method: "GET",
      }),
      providesTags: ["recordsList"]
    }),
    getRecordDetailed: builder.query<IRecord, { spaceId: string, listId: string, recordId: string }>({
      query: ({ spaceId, listId, recordId }) => ({
        url: `v2/spaces/${spaceId}/lists/${listId}/records/${recordId}`,
        method: "GET",
      }),
      providesTags: ["recordsList", "recordDetails"]
    }),
    createRecord: builder.mutation<JSendResponse, { spaceId: string, listId: string, payload: Partial<IRecord> }>({
      query: ({ spaceId, listId, payload }) => ({
        url: `v2/spaces/${spaceId}/lists/${listId}/records`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["recordsList", "recordDetails"]
    }),
    modifyRecord: builder.mutation<JSendResponse, { spaceId: string, listId: string, recordId: string, payload: Partial<IRecord> }>({
      query: ({ spaceId, listId, recordId, payload }) => ({
        url: `v2/spaces/${spaceId}/lists/${listId}/records/${recordId}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["recordsList", "recordDetails"]
    }),
    deleteRecord: builder.mutation<JSendResponse, { spaceId: string, listId: string, recordId: string }>({
      query: ({ spaceId, listId, recordId }) => ({
        url: `v2/spaces/${spaceId}/lists/${listId}/records/${recordId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["recordsList", "recordDetails"]
    }),
    setRecordProperties: builder.mutation<JSendResponse, { spaceId: string, listId: string, recordId: string, properties: Record<string, unknown> }>({
      query: ({ spaceId, listId, recordId, properties }) => ({
        url: `v2/spaces/${spaceId}/lists/${listId}/records/${recordId}/properties`,
        method: "PUT",
        body: {
          properties,
        }
      }),
      invalidatesTags: ["recordDetails"]
    }),
    removeRecordProperties: builder.mutation<JSendResponse, { spaceId: string, listId: string, recordId: string, properties: Array<string> }>({
      query: ({ spaceId, listId, recordId, properties }) => ({
        url: `v2/spaces/${spaceId}/lists/${listId}/records/${recordId}/properties`,
        method: "DELETE",
        body: {
          properties,
        }
      }),
      invalidatesTags: ["recordDetails"]
    }),
    setRecordPropertiesBatch: builder.mutation<JSendResponse, { spaceId: string, listId: string, entities: Array<string>, properties: Record<string, unknown> }>({
      query: ({ spaceId, listId, entities, properties }) => ({
        url: `v2/spaces/${spaceId}/lists/${listId}/records/batch/properties`,
        method: "PUT",
        body: {
          entities,
          properties,
        }
      }),
      invalidatesTags: ["recordsList", "recordDetails"]
    }),
    removeRecordPropertiesBatch: builder.mutation<JSendResponse, { spaceId: string, listId: string, entities: Array<string>, properties: Array<string> }>({
      query: ({ spaceId, listId, entities, properties }) => ({
        url: `v2/spaces/${spaceId}/lists/${listId}/records/batch/properties`,
        method: "DELETE",
        body: {
          entities,
          properties,
        }
      }),
      invalidatesTags: ["recordsList", "recordDetails"]
    }),
  }),
});


export const {
  useGetRecordsQuery, useLazyGetRecordsQuery,
  useGetRecordDetailedQuery, useLazyGetRecordDetailedQuery,
  useCreateRecordMutation,
  useModifyRecordMutation,
  useDeleteRecordMutation,
  useSetRecordPropertiesMutation,
  useRemoveRecordPropertiesMutation,
  useSetRecordPropertiesBatchMutation,
  useRemoveRecordPropertiesBatchMutation,
} = recordsApi;
