import { Flex, Popover, Tag } from "antd";
import { NamePath } from "antd/es/form/interface";
import { NoDndHandler } from "components/SortableList/lib";
import Watch from "components/Watch";
import CircleOutlined from "icons/circle-outlined";
import { getAntIconComponent } from "lib/utils/antIcon";
import { AntPresetColors } from "lib/utils/colors";
import { StatusIcons } from "pages/Lists/IconDropdown/lib";
import { FC } from "react";
import CodeInputFormItem from "./CodeInputFormItem";
import ColorPickerFormItem from "./ColorPickerFormItem";
import IconPickerFormItem from "./IconPickerFormItem";

type Props = {
  colorKey: NamePath;
  iconKey?: NamePath;
  codeKey?: NamePath;
}

const FormColorIconPicker: FC<Props> = (props) => {
  const { colorKey, iconKey, codeKey } = props;

  const OverlayComponent = (
    <Flex
      vertical
      gap="small"
      style={{
        maxWidth: 350,
        marginBottom: 5
      }}
      {...NoDndHandler}
    >
      {codeKey &&
        <>
          <Flex vertical gap="3px">
            <label
              htmlFor={codeKey}
              style={{
                fontSize: 13,
                fontWeight: 500,
                color: "var(--ant-form-label-color)",
              }}
            >
              Code
            </label>
            <CodeInputFormItem
              name={codeKey}
            />
          </Flex>
        </>
      }
      {iconKey &&
        <>
          <Flex vertical gap="3px">
            <label
              htmlFor={iconKey}
              style={{
                fontSize: 13,
                fontWeight: 500,
                color: "var(--ant-form-label-color)",
              }}
            >
              Icon
            </label>
            <IconPickerFormItem
              name={iconKey}
              icons={StatusIcons}
            />
          </Flex>
        </>
      }
      <Flex vertical gap="3px">
        <label
          htmlFor={colorKey}
          style={{
            fontSize: 13,
            fontWeight: 500,
            color: "var(--ant-form-label-color)",
          }}
        >
          Color
        </label>
        <ColorPickerFormItem
          name={colorKey}
          colors={AntPresetColors}
        />
      </Flex>
    </Flex>
  );

  return (
    <Popover
      trigger="click"
      content={OverlayComponent}
    >
      <div {...NoDndHandler} style={{ cursor: "pointer" }}>
        <Watch list={[colorKey, iconKey, codeKey]}>
          {([color, icon, code]) => {
            const IconComponent = getAntIconComponent(icon, CircleOutlined);
            const colorValue = AntPresetColors[color as keyof typeof AntPresetColors]?.primary;
            return (
              <>
                {iconKey &&
                  <>
                    {codeKey
                      ? (<Tag icon={<IconComponent style={{ color: colorValue }} />}>
                        {code}
                      </Tag>)
                      : <IconComponent style={{ color: colorValue }} />
                    }
                  </>
                }
                {!iconKey &&
                  <div
                    style={{
                      borderRadius: "50%",
                      backgroundColor: colorValue,
                      width: 10,
                      height: 10,
                    }}
                  />
                }
              </>
            );
          }}
        </Watch>
      </div>
    </Popover>
  );
};

export default FormColorIconPicker;
