import { Empty, theme } from "antd";
import { ColumnManagerDateFormatOptions, ColumnManagerDefaults, ColumnManagerTimeFormatOptions } from "components/ColumnManagerModal/lib";
import { TableDatePicker } from "components/DataTable/SimpleTable/FormItems/styled";
import { formatDate, processDate } from "lib/utils/date";
import { FC, memo } from "react";
import { EditableTableFormItemComponent } from "./lib";
import { DataColumnTimeFormats } from "types/data-component";
import { ReadOnlyField } from "../TableCell/styled";

const DateTime: FC<EditableTableFormItemComponent<string>> = (props) => {
  const { editable, column, value, onChange } = props;
  const { token } = theme.useToken();
  const formHandlers = { value: processDate(value), onChange };

  const dateFormat = ColumnManagerDateFormatOptions[column.dateFormat]?.formatter ?? ColumnManagerDefaults.dateFormat;
  const timeFormat = ColumnManagerTimeFormatOptions[column.timeFormat]?.formatter ?? ColumnManagerDefaults.timeFormat;

  const showTime = (column.timeFormat !== DataColumnTimeFormats.None);
  const format = [dateFormat, timeFormat].join(" ");

  return (
    <>
      {editable
        ? <TableDatePicker
          allowClear
          size="small"
          placeholder="Empty"
          hoverBorderColor={token.colorPrimary}
          format={format}
          showTime={showTime}
          {...formHandlers}
        />
        : <>
          {value
            ? <ReadOnlyField>{formatDate(value, format)}</ReadOnlyField>
            : <Empty />
          }
        </>
      }
    </>

  );
};

export default memo(DateTime);
