import { FC, memo } from "react";
import { EditableTableFormItemComponent } from "./lib";
import { Avatar } from "antd";
import { TableDataWrap } from "components/DataTable/SimpleTable/FormItems/styled";
import { IUser } from "types/user";

const Users: FC<EditableTableFormItemComponent<Array<IUser>>> = () => {
  return (
    <TableDataWrap>
      <Avatar
        size="small"
      />
    </TableDataWrap>
  );
};

export default memo(Users);
