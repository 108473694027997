import { ColumnManagerDateFormatOptions, ColumnManagerDefaults, ColumnManagerTimeFormatOptions } from "components/ColumnManagerModal/lib";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { IDataColumn } from "types/data-component";

dayjs.extend(LocalizedFormat);

export const DateFormats = {
  Default: "MMM DD, YYYY",
  Friendly: "MMM DD, YYYY",
  US: "MM/DD/YYYY",
  Europe: "DD/MM/YYYY",
  ISO: "YYYY-MM-DD",
  HumanFull: "ll h:mma",
  HumanShort: "ll"
} as const;

export const TimeFormats = {
  None: "",
  US: "h:mma",
  Europe: "HH:mm",
} as const;

type DateTimeFormatType =
  | typeof DateFormats[keyof typeof DateFormats]
  | typeof TimeFormats[keyof typeof TimeFormats];

export function formatDate(date: Date | string, format: DateTimeFormatType | string) {
  return dayjs(date).format(format);
}

export function formatDateFieldValue(value: string | Date, column: IDataColumn,) {
  const dateFormat = ColumnManagerDateFormatOptions[column.dateFormat]?.formatter ?? ColumnManagerDefaults.dateFormat;
  const timeFormat = ColumnManagerTimeFormatOptions[column.timeFormat]?.formatter ?? ColumnManagerDefaults.timeFormat;
  const format = [dateFormat, timeFormat].join(" ");

  return formatDate(value, format);
}

export function processDate(date: Date | string | null | undefined) {
  if (!date || (typeof date === "string" && date.length === 0)) {
    return null;
  }

  return dayjs(date);
}
