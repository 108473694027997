import { DrawerProps } from "antd";
import { useCallback, useState } from "react";

export interface UseDrawerConfig extends DrawerProps {
  defaultVisible?: boolean;
  useCommonProps?: boolean;
  useCustomStyles?: boolean;
}

export const useDrawer = (props?: UseDrawerConfig) => {
  const config = props ?? ({} as UseDrawerConfig);
  const {
    defaultVisible = false,
    // useCommonProps = true,
    // useCustomStyles = true,
    styles,
    ...drawerConfig
  } = config;

  const [isOpen, setIsOpen] = useState(defaultVisible);
  const showDrawer = useCallback(() => setIsOpen(true), [isOpen]);
  const closeDrawer = useCallback(() => setIsOpen(false), [isOpen]);

  const drawerProps: DrawerProps = {
    ...drawerConfig,
    open: isOpen,
    destroyOnClose: true,
    styles,
    onClose(e) {
      if (drawerConfig.onClose) {
        drawerConfig.onClose(e);
      }
      closeDrawer();
    },
  };

  return {
    isDrawerOpen: isOpen,
    showDrawer,
    closeDrawer,
    drawerProps,
  };
};
