import { FC, ReactNode } from "react";
import EmptyCell from "./Empty";
import { Tag } from "antd";

interface StatusProps {
  text: string;
  color?: string;
  icon?: ReactNode;
}

const TagCell: FC<StatusProps> = (props) => {
  const { text, color, icon } = props;

  if (!text?.length) {
    return <EmptyCell />;
  }
  return (
    <Tag
      icon={icon || null}
      color={color}
      style={{
        maxWidth: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis"
      }}
    >
      {text}
    </Tag>
  );
};

export default TagCell;
