import { FC, memo } from "react";
import { EditableTableFormItemComponent } from "./lib";
import { TableInputNumber } from "components/DataTable/SimpleTable/FormItems/styled";
import { ColumnManagerDefaults } from "components/ColumnManagerModal/lib";

const Currency: FC<EditableTableFormItemComponent<number>> = (props) => {
  const { column, value, onChange } = props;
  const formHandlers = { defaultValue: value, onBlur: onChange };
  const format = column.precision ?? ColumnManagerDefaults.precision;

  return (
    <TableInputNumber
      size="small"
      placeholder="Empty"
      precision={format}
      prefix={column.currency}
      {...formHandlers}
    />
  );
};

export default memo(Currency);
