import { IAccount, IAccountListItem } from "types/account";
import { rootApi } from "./root";
import { IEntityId } from "types/entity";
import { JSendResponse } from "types/jsend";
import { validateJSendResponse } from "redux/helpers";
import { sortCollection } from "lib/utils/data";

export const accountsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getAccountsList: builder.query<Array<IAccountListItem>, void>({
      query: () => ({
        url: "v2/accounts",
        method: "GET",
      }),
      providesTags: ["accountsList"],
    }),
    getAccountDetails: builder.query<IAccount, IEntityId>({
      query: (id) => {
        return {
          url: `v2/accounts/${id}`,
          method: "GET",
        };
      },
      transformResponse: (response: IAccount) => ({
        ...response,
        spaces: sortCollection(response.spaces, "id"),
      }),
      providesTags: ["accountDetails"],
    }),
    updateAccountDetails: builder.mutation<JSendResponse, Partial<IAccount> & Pick<IAccount, "id">>({
      query: ({ id, ...data }) => {
        return {
          url: `v2/accounts/${id}`,
          method: "PUT",
          body: data,
        };
      },
      async onQueryStarted({ id, ...payload }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          accountsApi.util.updateQueryData("getAccountDetails", id, (draft) => {
            if (draft) {
              Object.assign(draft, payload);
            }
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: ["accountsList"],
      transformResponse: validateJSendResponse,
    }),
    inviteAdmins: builder.mutation<JSendResponse, { id: string; emails: Array<string> }>({
      query: ({ id, ...data }) => {
        return {
          url: `v2/accounts/${id}/invite`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["accountDetails"],
      transformResponse: validateJSendResponse,
    }),
    revokeAdmins: builder.mutation<JSendResponse, { id: string; ids: Array<IEntityId> }>({
      query: ({ id, ...data }) => {
        return {
          url: `v2/accounts/${id}/revoke`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["accountDetails"],
      transformResponse: validateJSendResponse,
    }),
  })
});

export const {
  useGetAccountsListQuery, useLazyGetAccountsListQuery,
  useGetAccountDetailsQuery, useLazyGetAccountDetailsQuery,
  useUpdateAccountDetailsMutation,
  useInviteAdminsMutation,
  useRevokeAdminsMutation,
} = accountsApi;
