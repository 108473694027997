import {
  BellOutlined,
  CalendarOutlined,
  CameraOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  CloudOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  HeartOutlined,
  HomeOutlined,
  IssuesCloseOutlined,
  LockOutlined,
  MailOutlined,
  PhoneOutlined,
  PlusOutlined,
  SearchOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  SmileOutlined,
  StarOutlined,
  UploadOutlined,
  UserOutlined
} from "@ant-design/icons";

export const ListIcons = [
  HomeOutlined,
  SettingOutlined,
  UserOutlined,
  BellOutlined,
  SearchOutlined,
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  UploadOutlined,
  DownloadOutlined,
  HeartOutlined,
  StarOutlined,
  CloudOutlined,
  MailOutlined,
  PhoneOutlined,
  LockOutlined,
  ShoppingCartOutlined,
  CameraOutlined,
  CalendarOutlined,
  SmileOutlined
];

export const StatusIcons = [
  IssuesCloseOutlined,
  ClockCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
];
