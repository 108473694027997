import { EyeOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Flex, Upload } from "antd";
import { TableDataWrap } from "components/DataTable/SimpleTable/FormItems/styled";
import FileViewerDrawer from "components/FileViewerDrawer";
import { FC, memo } from "react";
import { IFile } from "types/helpers";
import { EditableTableFormItemComponent } from "./lib";

// FIXME: provide with upload logic
const File: FC<EditableTableFormItemComponent<IFile>> = (props) => {
  const { value } = props;

  return (
    <TableDataWrap>
      <Flex gap="small">
        {!value &&
          <Upload>
            <Button
              size="small"
              icon={<UploadOutlined />}
            />
          </Upload>
        }
        {value &&
          <>
            <Flex justify="center" gap="small">
              <FileViewerDrawer
                document={value}
                triggerElement={
                  <Button
                    size="small"
                    icon={<EyeOutlined style={{ fontSize: 14, flexShrink: 0 }} />}
                  />
                }
              />
              {value?.name}
            </Flex>
          </>
        }
      </Flex>
    </TableDataWrap>
  );
};

export default memo(File);
