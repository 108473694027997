import { FC, HTMLAttributes } from "react";
import IconWrap from "icons/IconWrap";

const ReferenceOutlined: FC<HTMLAttributes<HTMLSpanElement>> = (props) => {
  return (
    <IconWrap {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 896 896"
        fill="currentColor"
      >
        <path xmlns="http://www.w3.org/2000/svg"
          d="M683.329 178.46L184.324 213.745C180.505 214.028 176.899 215.654 174.141 218.412L111.562 280.991C106.33 286.223 110.431 295.133 117.785 294.567L580.374 261.899L166.575 675.698C163.464 678.809 163.464 683.9 166.575 687.011L209.002 729.438C212.113 732.549 217.204 732.549 220.315 729.438L634.044 315.71L601.375 778.299C600.88 785.723 609.719 789.754 614.952 784.521L679.652 719.821C680.996 718.478 681.844 716.639 681.915 714.73L717.553 212.684C717.872 208.108 717.206 203.516 715.599 199.219C713.992 194.922 711.482 191.019 708.238 187.775C704.994 184.531 701.091 182.021 696.794 180.414C692.497 178.807 687.906 178.141 683.329 178.46Z"
        />
      </svg>
    </IconWrap>
  );
};

export default ReferenceOutlined;
