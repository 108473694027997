import { Input } from "antd";
import { useTr } from "lib/i18n";
import styled from "@emotion/styled";

const InputWrap = styled(Input)`
  border-color: transparent;
`;

const EmptyCell = () => {
  const { T } = useTr();
  return (
    <InputWrap
      placeholder={T("general.Empty")}
      size="small"
      variant="borderless"
      disabled
    />
  );
};

export default EmptyCell;
