import { message } from "antd";
import { captureSentryException } from "lib/monitoring/sentry";

export function formatBytes(bytes: number, decimals: number = 2): string {
  if (!Number.isFinite(bytes) || bytes <= 0) {
    return "0 Bytes"; // Return "0 Bytes" for invalid or non-positive inputs
  }

  const k = 1024;
  const dm = Math.max(0, decimals); // Ensure decimals is not negative
  const sizes = ["Bytes", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];

  const i = bytes < 1 ? 0 : Math.min(Math.floor(Math.log(bytes) / Math.log(k)), sizes.length - 1);

  const formattedSize = (bytes / Math.pow(k, i)).toFixed(dm);

  return `${formattedSize} ${sizes[i]}`;
}

export async function downloadFile(url: string, filename?: string): Promise<void> {
  message.info("Your download will start soon");

  try {
    // Fetch the file
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Failed to fetch file: ${response.statusText}`);
    }

    // Create a blob from the response
    const blob = await response.blob();

    // Create a temporary <a> element for download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);

    // Use provided filename or fallback to a default
    link.download = filename || url.split("/").pop() || "download";

    // Trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);

    message.success("File has been downloaded!");
  } catch (error) {
    captureSentryException(error, true, "Error downloading the file");
  }
}
