import { FC, Fragment } from "react";
import { IDataColumnType } from "types/data-component";
import Boolean from "./Boolean";
import Currency from "./Currency";
import Date from "./Date";
import DateTime from "./DateTime";
import File from "./File";
import Files from "./Files";
import Formula from "./Formula";
import MultiLineText from "./MultiLineText";
import MultiSelect from "./MultiSelect";
import Number from "./Number";
import Percent from "./Percent";
import Reference from "./Reference";
import SingleLineText from "./SingleLineText";
import SingleSelect from "./SingleSelect";
import User from "./User";
import Users from "./Users";
import { EditableTableFormItemComponent } from "./lib";

// eslint-disable-next-line react-refresh/only-export-components, @typescript-eslint/no-explicit-any
const ComponentMap: Record<IDataColumnType, FC<EditableTableFormItemComponent<any>>> = {
  Number: Number,
  User: User,
  SingleLineText: SingleLineText,
  MultiLineText: MultiLineText,
  SingleSelect: SingleSelect,
  MultiSelect: MultiSelect,
  Currency: Currency,
  Percent: Percent,
  Formula: Formula,
  Boolean: Boolean,
  Users: Users,
  Date: Date,
  DateTime: DateTime,
  File: File,
  Files: Files,
  Reference: Reference
};

export const renderTableCellComponent = (columnType?: IDataColumnType) => columnType
  ? ComponentMap[columnType]
  : Fragment;
