import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "redux/store";

import App from "./App";
import configuration from "configuration";
import { AntConfigProvider } from "lib/context/AntConfig";
import { Talkr } from "lib/i18n";
import { initLogRocket } from "lib/monitoring/logrocket";
import { initSentry, SentryErrorBoundary } from "lib/monitoring/sentry";
import ErrorPage from "pages/ErrorPage";
import "./index.css";
import { FormRegistryProvider } from "lib/context/FormRegistryContext";

if (configuration.app.isProduction) {
  initSentry();
  initLogRocket();
}

ReactDOM.createRoot(document.getElementById("root")!)
  .render(
    <React.StrictMode>
      <SentryErrorBoundary
        fallback={(errorData) => (
          <ErrorPage type="error" description={errorData.componentStack} />
        )}
      >
        <Provider store={store}>
          <FormRegistryProvider>
            <Talkr>
              <AntConfigProvider>
                <App />
              </AntConfigProvider>
            </Talkr>
          </FormRegistryProvider>
        </Provider>
      </SentryErrorBoundary>,
    </React.StrictMode>,
  );
