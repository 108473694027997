export const ApryseWebViewerPath = "/apryse/lib";
export const ApryseStylesPath = "/css/apryse.css";

export const ApryseCompatibleFormats = [
  // PDF FORMATS
  ".pdf",
  ".fdf",
  ".xfdf",

  // MICROSOFT OFFICE FORMATS
  ".doc",
  ".docx",
  ".xls",
  ".xlsx",
  ".ppt",
  ".pptx",
  // ".pub", // (REQUIRES SERVER SETUP)

  // CAD FORMATS (REQUIRES SERVER SETUP)
  // ".dwg",
  // ".dxf",
  // ".dgn",
  // ".dwf",

  // DOCUMENT FORMATS
  ".rtf",
  ".odt",
  ".ods",
  ".odp",
  ".wpf",

  // IMAGE FORMATS
  ".bmp",
  ".wmf",
  ".emf",
  ".gif",
  ".hdp",
  ".jpg",
  ".jpeg",
  ".jp2",
  ".jpc",
  ".png",
  // ".tif", // (REQUIRES ADDITIONAL CONFIGURATION)
  // ".tiff", // (REQUIRES ADDITIONAL CONFIGURATION)

  // WEB FORMATS (REQUIRES SERVER SETUP)
  // ".html",
  // ".htm",
  // ".mht",
  // ".svg",

  // VIDEO FORMATS (REQUIRES ADDITIONAL CONFIGURATION)
  // ".mp4",
  // ".ogg",
  // ".webm",

  // EMAIL FORMATS (REQUIRES OUTLOOK INSTALLATION)
  // ".msg",
  // ".eml",

  // TEXT FORMATS (REQUIRES SERVER SETUP)
  // ".txt",
  // ".csv",

  // BIM/3D FORMATS (REQUIRES SERVER SETUP)
  // ".ifc",
  // ".rvt",

  // OPEN XML FORMATS (REQUIRES SERVER SETUP)
  // ".xps",
  // ".oxps",
  // ".xml",
  // ".xod",
  // ".xaml",
];

export const isApryseViewerCompatibleFormat = (extension?: string): boolean => {
  if (!extension) {
    return false;
  }
  return ApryseCompatibleFormats.includes(extension.toLowerCase());
};

