import {
  ControlOutlined,
  PoweroffOutlined,
  SettingOutlined,
  UserOutlined
} from "@ant-design/icons";
import { Link } from "@tanstack/react-router";
import type { MenuProps } from "antd";
import { Avatar, Dropdown } from "antd";
import { ItemType } from "antd/es/menu/interface";
import ThemeSwitch from "components/ThemeSwitch";
import {
  UserSettingsEmail,
  UserSettingsName
} from "components/UserSettingsDropdown/styled";
import { useTr } from "lib/i18n";
import { setStoredAuthToken } from "lib/utils/auth";
import { getUserFullName } from "lib/utils/user";
import { FC, memo } from "react";
import { useGetAccountsListQuery } from "redux/services/accountsApi";
import { useGetProfileQuery, userApi } from "redux/services/userApi";
import { useAppDispatch } from "redux/store";

const UserSettingsDropdown: FC = () => {
  const { T } = useTr();
  const dispatch = useAppDispatch();
  const { data: user } = useGetProfileQuery();
  const { data: accounts, isFetching } = useGetAccountsListQuery();

  const getAccountsMenuSection = (): ItemType[] => {
    if (isFetching || !accounts?.length) {
      return [];
    } else {
      return [
        {
          type: "group",
          label: "Accounts",
          children: accounts.map(account => (
            {
              label: <Link to="/account/$id" params={{ id: account.uid }}>{account.title}</Link>,
              key: account.uid,
              icon: <ControlOutlined />,
              style: ({ overflow: "hidden" }),
            }
          )),
        },
        {
          type: "divider",
        }
      ];
    }
  };

  const items: MenuProps["items"] = [

    // User and its settings
    {
      type: "group",
      label: (
        <>
          <UserSettingsName>{getUserFullName(user)}</UserSettingsName>
          <UserSettingsEmail>{user?.email}</UserSettingsEmail>
        </>
      ),
      children: [
        {
          label: <Link to="/settings">{T("menuItems.Settings")}</Link>,
          key: "settings",
          icon: <SettingOutlined />,
        },
      ],
    },
    {
      type: "divider",
    },

    // Accounts
    ...getAccountsMenuSection(),

    // There switcher
    {
      type: "group",
      label: "Theme",
    },
    {
      type: "group",
      label: <ThemeSwitch />,
    },
    {
      type: "divider",
    },

    // Log out
    {
      label: T("menuItems.LogOut"),
      key: "logout",
      icon: <PoweroffOutlined />,
      danger: true,
      onClick: () => {
        setStoredAuthToken(null);
        dispatch(userApi.util.resetApiState());
      }
    },
  ];

  return (
    <Dropdown
      menu={{ items }}
      placement="bottomRight"
      trigger={["click"]}
      overlayStyle={{
        minWidth: 250,
        maxWidth: 250,
        animationDuration: "0s"
      }}
    >
      <Avatar icon={<UserOutlined />} style={{ cursor: "pointer" }} />
    </Dropdown>
  );
};

export default memo(UserSettingsDropdown);
