import { Collapse, CollapseProps, Form, FormInstance, Typography } from "antd";
import { FC } from "react";

interface FormDebuggerProps {
  form: FormInstance;
}

const FormDebugger: FC<FormDebuggerProps> = (props) => {
  const { form } = props;

  const items: CollapseProps["items"] = [{
    key: "debugger",
    label: <Typography.Text code>Form debugger</Typography.Text>,
    children: (
      <Form.Item noStyle shouldUpdate>
        {() => (
          <Typography style={{ fontSize: 10 }}>
            <pre>{JSON.stringify(form.getFieldsValue(), null, 2)}</pre>
          </Typography>
        )}
      </Form.Item>
    )
  }];

  return (
    <Collapse
      items={items}
      size="small"
      expandIconPosition="end"
      ghost
    />
  );
};

export default FormDebugger;
