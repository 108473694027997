import { ArrowLeftOutlined, ArrowRightOutlined, DeleteOutlined, EditOutlined, EyeInvisibleOutlined, SortAscendingOutlined, SortDescendingOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Dropdown } from "antd";
import ColumnManagerModal from "components/ColumnManagerModal";
import { Sorts } from "components/DataTable/lib";
import SyntheticMenuItem from "components/Synthetic/SyntheticMenuItem";
import { deleteConfirm } from "lib/actions/confirmDelete";
import { MoveDirection } from "lib/utils/data";
import { FC } from "react";
import { IDataColumn } from "types/data-component";

interface ContextMenuProps extends MenuProps {
  onDelete?: () => void;
  onModify?: (data: Partial<IDataColumn>) => void;
  onMove?: (direction: MoveDirection) => void;
  onSort?: (data: Sorts<unknown>) => void;
  column?: IDataColumn;
}

const ContextMenu: FC<ContextMenuProps> = (props) => {
  const { column, onDelete, onModify, onMove, onSort } = props;

  const columnOperations: MenuProps["items"] = (column)
    ? [
      {
        key: "sort-asc",
        label: "Sort ascending",
        icon: <SortAscendingOutlined />,
        onClick: onSort && (() => onSort({ order: "ascend", columnKey: column.code })),
        disabled: !onSort,
      },
      {
        key: "sort-desc",
        label: "Sort descending",
        icon: <SortDescendingOutlined />,
        onClick: onSort && (() => onSort({ order: "descend", columnKey: column.code })),
        disabled: !onSort,
      },

      {
        key: "move-left",
        label: "Move left",
        icon: <ArrowLeftOutlined />,
        onClick: onMove && (() => onMove("left")),
        disabled: !onMove,
      },
      {
        key: "move-right",
        label: "Move right",
        icon: <ArrowRightOutlined />,
        onClick: onMove && (() => onMove("right")),
        disabled: !onMove,
      }
    ]
    : [];

  const columnActions: MenuProps["items"] = (column)
    ? [
      {
        key: "column-hide",
        label: "Hide column",
        icon: <EyeInvisibleOutlined />,
        onClick: onModify && (() => onModify({ hidden: true })),
        disabled: !onModify,
      },
      {
        key: "column-delete",
        label: "Delete column",
        danger: true,
        icon: <DeleteOutlined />,
        disabled: !onDelete,
        onClick() {
          deleteConfirm({
            onOk: onDelete,
          });
        }
      }
    ]
    : [];

  const columnHandlers = [
    {
      key: "edit",
      label: (
        <ColumnManagerModal
          initialValues={column}
          onSubmit={onModify}
          triggerElement={
            <SyntheticMenuItem
              icon={<EditOutlined />}
              title="Edit field"
            />
          }
        />
      ),
      disabled: !onModify,
      style: { padding: 0 },
    },
  ];

  const items: MenuProps["items"] = [
    ...columnHandlers,
    ...columnOperations,
    ...columnActions,
  ];

  // @ts-expect-error - Type is not configured yet
  const menuItems = items.filter(item => !item?.disabled);

  return (
    <Dropdown
      menu={{ items: menuItems }}
      trigger={["contextMenu", "click"]}
    >
      {props.children}
    </Dropdown>
  );
};

export default ContextMenu;
