import { Flex, FlexProps } from "antd";
import { FC, ReactNode } from "react";

type Props = Omit<FlexProps, "children"> & {
  icon?: ReactNode;
  title?: string;
}

const SyntheticMenuItem: FC<Props> = (props) => {
  const { icon, title, ...flexProps } = props;

  return (
    <Flex
      {...flexProps}
      style={{
        flexGrow: 1,
        padding: "5px 12px",
      }}
    >
      {icon &&
        <span role="img" aria-label="sort-ascending" className="anticon anticon-sort-ascending ant-dropdown-menu-item-icon">
          {icon}
        </span>
      }
      <span className="ant-dropdown-menu-title-content">
        {title}
      </span>
    </Flex>
  );
};

export default SyntheticMenuItem;
