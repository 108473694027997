import { Flex, Form } from "antd";
import { renderTableCellComponent } from "components/DataTable/SimpleTable/FormItems";
import PropertyTitle from "components/DataTable/SimpleTable/PropertyTitle";
import { getTableColumnIcon, getTableFormItemProps } from "lib/utils/table";
import { IDataColumn } from "types/data-component";
import { IBaseEntity } from "types/entity";
import { DataListPropertyValue } from "./styled";

type Props<T extends IBaseEntity> = {
  field: IDataColumn;
  item: T;
}

const Row = <T extends IBaseEntity>(props: Props<T>) => {
  const { field } = props;

  const Node = renderTableCellComponent(field.type);
  const { editable, ...formItemProps } = getTableFormItemProps(field);

  return (
    <>
      <Flex align="center">
        <PropertyTitle
          icon={getTableColumnIcon(field.type)}
          title={field.title}
          detailedPage
        />
      </Flex>
      <DataListPropertyValue align="center">
        <Form.Item
          name={field.code}
          style={{ margin: 0 }}
          {...formItemProps}
        >
          <Node
            column={field}
            editable={editable}
          />
        </Form.Item>
      </DataListPropertyValue>
    </>
  );
};

export default Row;
