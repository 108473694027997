import Form, { useForm } from "antd/es/form/Form";
import useBreakpoints from "lib/hooks/useBreakpoints";
import { IDataColumn } from "types/data-component";
import { IBaseEntity } from "types/entity";
import { FieldData } from "../../../node_modules/rc-field-form/lib/interface";
import Row from "./Row";
import { DataListGrid } from "./styled";

type Props<T extends IBaseEntity> = {
  fields: Array<IDataColumn>;
  item: T;
  onPatch?: (values: Record<string, unknown>) => void;
  oneCol?: boolean;
  disabled?: boolean
}

const DataList = <T extends IBaseEntity>(props: Props<T>) => {
  const { item, fields, onPatch, oneCol, disabled = false } = props;
  const [form] = useForm();
  const breakpoints = useBreakpoints();

  const handleOnFieldsChange = (changedFields: FieldData[]) => {
    if (onPatch && changedFields.length) {
      const result: Record<string, unknown> = {};
      changedFields.forEach(item => {
        if (item.name && item.name.length > 0) {
          result[item.name[0]] = item.value;
        }
      });
      console.log("[DataList] Row.handleOnFieldsChange", result);
      onPatch(result);
    }
  };

  return (
    <DataListGrid
      breakpoints={breakpoints}
      oneCol={oneCol}
    >
      <Form
        key={item.uid}
        form={form}
        initialValues={item}
        component={false}
        onFieldsChange={handleOnFieldsChange}
        disabled={disabled}
      >
        {fields.map((prop) => (
          <Row
            key={prop.id}
            field={prop}
            item={item}
          />
        ))}
      </Form>
    </DataListGrid>
  );
};

export default DataList;
