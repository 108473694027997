import { arrayMove } from "@dnd-kit/sortable";
import { ICollectionBase } from "types/entity";

export function sortCollection<T>(
  collection: T[],
  key: keyof T = "order" as keyof T
) {
  return Array.from(collection).sort((a, b) => {
    const valueA = a[key];
    const valueB = b[key];

    // Ensure values are numbers before performing arithmetic
    if (typeof valueA === "number" && typeof valueB === "number") {
      return valueA - valueB;
    }

    // Fallback for non-number cases (if applicable in your use case)
    return 0;
  });
}

export function getReorderPayload<T extends ICollectionBase>(collection: T[]) {
  return collection.map((item, index) => ({ id: item.id, order: index }));
}

export function moveCollectionItem<T extends ICollectionBase>(collection: T[], itemId: number, direction: MoveDirection) {
  const currentIndex = collection.findIndex((col) => col.id === itemId);
  if (currentIndex === -1) {
    return collection;
  }

  const targetIndex = direction === "left"
    ? currentIndex - 1
    : currentIndex + 1;

  if (targetIndex < 0 || targetIndex >= collection.length) {
    return collection;
  }

  const reordered = arrayMove(collection, currentIndex, targetIndex);
  return reordered.map((col, index) => ({
    ...col,
    order: index + 1,
  }));
}

export type MoveDirection = "left" | "right";

// eslint-disable-next-line @typescript-eslint/ban-types
export const debounce = (func: Function, delay: number = 1_000) => {
  let timeoutId: NodeJS.Timeout;
  return (...args: unknown[]) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export type ExtractKeys<T> = T extends object
  ? {
    [K in keyof T & string]:
    | K
    | (T[K] extends object ? `${K}.${ExtractKeys<T[K]>}` : K);
  }[keyof T & string]
  : never;

export function getNestedPropertyValue<T extends object>(obj: T, key: ExtractKeys<T>) {
  const keys = key.split(".");
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let result: any = obj;

  for (const k of keys) {
    result = result[k];
  }

  return result;
}
