import { message } from "antd";
import { captureClassMethodException } from "../monitoring/sentry";

// Define the ILogger interface
export interface ILogger {
  info: (msg: unknown, ...rest: unknown[]) => void;
  debug: (msg: unknown, ...rest: unknown[]) => void;
  warn: (msg: unknown, ...rest: unknown[]) => void;
  error: (msg: unknown, ...rest: unknown[]) => void;
}

export default abstract class BaseClassLoggable {
  public readonly LogPrefix: string;
  readonly logger: ILogger;

  constructor() {
    this.LogPrefix = `[${this.constructor.name}]`;
    this.logger = createLogger({ prefix: this.LogPrefix });
  }

  protected getMethodName(method: string) {
    return this.constructor.name + "." + method;
  }

  protected handleError(error: unknown, methodName: string, showUiMessage?: boolean, uiMessageText?: string) {
    this.logger.error(methodName, error);
    captureClassMethodException(error, this.constructor.name, methodName);

    if (showUiMessage) {
      message.error(uiMessageText);
    }
  }
}

// TODO: support "development"-only logging
export function createLogger(options: { prefix: string; }): ILogger {
  return {
    info: (msg: unknown, ...rest: unknown[]) => console.info(`${options.prefix} `, msg, ...rest),
    debug: (msg: unknown, ...rest: unknown[]) => console.debug(`${options.prefix} `, msg, ...rest),
    warn: (msg: unknown, ...rest: unknown[]) => console.warn(`${options.prefix} `, msg, ...rest),
    error: (msg: unknown, ...rest: unknown[]) => console.error(`${options.prefix} `, msg, ...rest),
  };
}

