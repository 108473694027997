import { Modal, ModalFuncProps } from "antd";
import { getTr } from "lib/i18n/getTr";

export function deleteConfirm(props: ModalFuncProps & { itemName?: string }) {
  const { itemName, ...modalProps } = props;
  return Modal.confirm({
    title: `Do you want to delete ${itemName ?? "this item"}?`,
    content: "This will permanently delete the item. This item will no longer be accessible to you or anyone else. This action is irreversible.",
    okText: getTr("general.Delete"),
    okType: "danger",
    cancelText: getTr("general.Cancel"),
    ...modalProps,
  });
}
