import dayjs from "dayjs";
import { DateFormats, TimeFormats } from "lib/utils/date";
import { getColumnFieldType } from "lib/utils/table";
import { DataColumnClasses, DataColumnDateFormats, DataColumnTimeFormats, DataColumnTypes, IDataColumn } from "types/data-component";

type SettingsExtra = {
  description: string;
  optionKeys: Array<keyof IDataColumn>;
}

export type IColumnManagerCustomItem = Pick<IDataColumn, "title" | "class" | "type"> & SettingsExtra;
export type IColumnManagerSystemItem = Pick<IDataColumn, "title" | "class" | "type" | "code"> & SettingsExtra;

// TODO: Support i18n
export const ColumnManagerCustomItems: Array<IColumnManagerCustomItem> = [
  {
    title: getColumnFieldType(DataColumnTypes.SingleLineText),
    description: "A single line of text.",
    type: DataColumnTypes.SingleLineText,
    class: DataColumnClasses.Custom,
    optionKeys: [],
  },
  {
    title: getColumnFieldType(DataColumnTypes.MultiLineText),
    description: "A long text field that can span multiple lines.",
    type: DataColumnTypes.MultiLineText,
    class: DataColumnClasses.Custom,
    optionKeys: [],
  },
  {
    title: getColumnFieldType(DataColumnTypes.SingleSelect),
    description: "Single select allows you to select a single option from predefined options in a dropdown.",
    type: DataColumnTypes.SingleSelect,
    class: DataColumnClasses.Custom,
    optionKeys: ["options"],
  },
  {
    title: getColumnFieldType(DataColumnTypes.MultiSelect),
    description: "Multiple select allows you to select one or more predefined options listed below.",
    type: DataColumnTypes.MultiSelect,
    class: DataColumnClasses.Custom,
    optionKeys: ["options"],
  },
  {
    title: getColumnFieldType(DataColumnTypes.Number),
    description: "",
    type: DataColumnTypes.Number,
    class: DataColumnClasses.Custom,
    optionKeys: ["precision"],
  },
  {
    title: getColumnFieldType(DataColumnTypes.Currency),
    description: "",
    type: DataColumnTypes.Currency,
    class: DataColumnClasses.Custom,
    optionKeys: ["currency", "precision"],
  },
  {
    title: getColumnFieldType(DataColumnTypes.Percent),
    description: "",
    type: DataColumnTypes.Percent,
    class: DataColumnClasses.Custom,
    optionKeys: ["precision"],
  },
  {
    title: getColumnFieldType(DataColumnTypes.Formula),
    description: "",
    type: DataColumnTypes.Formula,
    class: DataColumnClasses.Custom,
    optionKeys: [],
  },
  {
    title: getColumnFieldType(DataColumnTypes.Boolean),
    description: "A single checkbox that can be checked or unchecked.",
    type: DataColumnTypes.Boolean,
    class: DataColumnClasses.Custom,
    optionKeys: [],
  },
  {
    title: getColumnFieldType(DataColumnTypes.User),
    description: "A users field lets you add collaborators to your records.",
    type: DataColumnTypes.User,
    class: DataColumnClasses.Custom,
    optionKeys: [],
  },
  {
    title: getColumnFieldType(DataColumnTypes.Users),
    description: "A users field lets you add collaborators to your records.",
    type: DataColumnTypes.Users,
    class: DataColumnClasses.Custom,
    optionKeys: [],
  },
  {
    title: getColumnFieldType(DataColumnTypes.Date),
    description: "Enter a date (e.g. 11/12/2013) or pick one from a calendar.",
    type: DataColumnTypes.Date,
    class: DataColumnClasses.Custom,
    optionKeys: ["dateFormat"],
  },
  {
    title: getColumnFieldType(DataColumnTypes.DateTime),
    description: "Enter a date (e.g. 11/12/2013) or pick one from a calendar.",
    type: DataColumnTypes.DateTime,
    class: DataColumnClasses.Custom,
    optionKeys: ["dateFormat", "timeFormat"],
  },
  {
    title: getColumnFieldType(DataColumnTypes.File),
    description: "Attachments allow you to add images, documents, or other files which can then be viewed or downloaded.",
    type: DataColumnTypes.File,
    class: DataColumnClasses.Custom,
    optionKeys: [],
  },
  {
    title: getColumnFieldType(DataColumnTypes.Files),
    description: "Attachments allow you to add images, documents, or other files which can then be viewed or downloaded.",
    type: DataColumnTypes.Files,
    class: DataColumnClasses.Custom,
    optionKeys: [],
  },
];

export const ColumnManagerSystemItems: Array<IColumnManagerSystemItem> = [
  {
    title: "Date created",
    description: "A created time field automatically shows the date and time that each record was created. It cannot be edited.",
    type: DataColumnTypes.DateTime,
    class: DataColumnClasses.System,
    code: "created_at",
    optionKeys: ["dateFormat", "timeFormat"],
  },
  {
    title: "Date updated",
    description: "Shows the date and time that a record was most recently modified in any editable field or just in specific editable fields.",
    type: DataColumnTypes.DateTime,
    class: DataColumnClasses.System,
    code: "updated_at",
    optionKeys: ["dateFormat", "timeFormat"],
  },
  {
    title: "Created by",
    description: "A created by field automatically shows the collaborator who created the record. It cannot be edited.",
    type: DataColumnTypes.User,
    class: DataColumnClasses.System,
    code: "createdBy",
    optionKeys: [],
  },
  {
    title: "Updated by",
    description: "Shows the collaborator that a record was most recently modified by in any editable field or just in specific editable fields.",
    type: DataColumnTypes.User,
    class: DataColumnClasses.System,
    code: "updatedBy",
    optionKeys: [],
  },
  {
    title: "ID",
    description: "An ID field shows the unique human-readable identifier of the record. It cannot be edited.",
    type: DataColumnTypes.SingleLineText,
    class: DataColumnClasses.System,
    code: "uid",
    optionKeys: [],
  },
];

export const ColumnManagerDateFormatOptions = {
  [DataColumnDateFormats.Default]: {
    label: "Default (Today)",
    value: DataColumnDateFormats.Default,
    formatter: DateFormats.Default,
  },
  [DataColumnDateFormats.Friendly]: {
    label: `Friendly (${dayjs().format(DateFormats.Friendly)})`,
    value: DataColumnDateFormats.Friendly,
    formatter: DateFormats.Friendly,
  },
  [DataColumnDateFormats.Usa]: {
    label: `US (${dayjs().format(DateFormats.US)})`,
    value: DataColumnDateFormats.Usa,
    formatter: DateFormats.US,
  },
  [DataColumnDateFormats.Europe]: {
    label: `Europe (${dayjs().format(DateFormats.Europe)})`,
    value: DataColumnDateFormats.Europe,
    formatter: DateFormats.Europe,
  },
  [DataColumnDateFormats.Iso]: {
    label: `ISO (${dayjs().format(DateFormats.ISO)})`,
    value: DataColumnDateFormats.Iso,
    formatter: DateFormats.ISO,
  },
};

export const ColumnManagerTimeFormatOptions = {
  [DataColumnTimeFormats.None]: {
    label: "Disabled",
    value: DataColumnTimeFormats.None,
    formatter: TimeFormats.None,
  },
  [DataColumnTimeFormats.Usa]: {
    label: `12 hour (${dayjs().format(TimeFormats.US)})`,
    value: DataColumnTimeFormats.Usa,
    formatter: TimeFormats.US,
  },
  [DataColumnTimeFormats.Europe]: {
    label: `24 hour (${dayjs().format(TimeFormats.Europe)})`,
    value: DataColumnTimeFormats.Europe,
    formatter: TimeFormats.Europe,
  },
};

export const ColumnManagerDefaults: Partial<IDataColumn> = {
  width: 150,
  dateFormat: DataColumnDateFormats.Default,
  timeFormat: DataColumnTimeFormats.None,
  precision: 2,
  currency: "USD",
};
