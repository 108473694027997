import styled from "@emotion/styled";
import { Flex } from "antd";
import { css } from "@emotion/react";
import { transientOptions } from "styles/common";

export const PropertyTitleWrap = styled(Flex, transientOptions) <{
  $pointer?: boolean,
  $detailedPage?: boolean,
  $formView?: boolean
}>`
  flex-grow: 1;
  height: 100%;
  padding: 6px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: ${({ $pointer }) => $pointer ? "pointer" : "default"}; ;
  ${({ $detailedPage }) => $detailedPage && css`
    padding: 8px 0;
  `}
  ${({ $formView }) => $formView && css`
    padding: 0;
  `}
`;
