import { FormInstance } from "antd";
import React, { createContext, useContext, useEffect, useRef, useCallback } from "react";

type FormRegistryContextType = {
  getForms: () => Record<string, FormInstance>;
  getForm: (id: string) => FormInstance;
  resetForms: () => void;
  useRegisterForm: (id: string, form: FormInstance) => void;
};

const FormRegistryContext = createContext<FormRegistryContextType | null>(null);

export const FormRegistryProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const formsRef = useRef<Record<string, FormInstance>>({});

  const useRegisterForm = (id: string, form: FormInstance) => {
    useEffect(() => {
      formsRef.current[id] = form;

      return () => {
        delete formsRef.current[id];
      };
    }, [id, form]);
  };

  const getForms = () => formsRef.current;

  const resetForms = () => {
    Object.values(formsRef.current).forEach((form) => {
      if (form.resetFields) {
        form.resetFields();
      }
    });
  };

  const getForm = useCallback((id: string) => formsRef.current[id], []);

  return (
    <FormRegistryContext.Provider value={{ useRegisterForm, getForms, getForm, resetForms }}>
      {children}
    </FormRegistryContext.Provider>
  );
};

export const useFormRegistry = () => {
  const context = useContext(FormRegistryContext);
  if (!context) {
    throw new Error("useFormRegistry must be used within a FormRegistryProvider");
  }

  return context;
};
