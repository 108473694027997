import { ModalProps, theme } from "antd";
import { ModalCommonProps } from "lib/utils/modal";
import { useCallback, useState } from "react";

export interface UseModalConfig extends ModalProps {
  defaultVisible?: boolean;
  useCommonProps?: boolean;
  useCustomStyles?: boolean;
}

export const useModal = (config?: UseModalConfig,) => {
  const modalConfig = config ?? ({} as UseModalConfig);
  const {
    defaultVisible = false,
    useCommonProps = true,
    useCustomStyles = true,
    styles,
    ...restConfig
  } = modalConfig;

  const { token } = theme.useToken();
  const [isOpen, setIsOpen] = useState(defaultVisible);
  const showModal = useCallback(() => setIsOpen(true), [isOpen]);
  const closeModal = useCallback(() => setIsOpen(false), [isOpen]);

  const customStyles: ModalProps["styles"] = {
    header: {
      display: "flex",
      alignItems: "center",
      height: 56,
      margin: 0,
      padding: "16px 24px",
      borderBottom: `1px solid ${token.colorBgContainerDisabled}`,
    },
    content: {
      padding: 0,
    },
    body: {
      maxHeight: 500,
      overflowY: "scroll",
      padding: "20px 24px",
    },
    footer: {
      margin: 0,
      padding: "16px 24px",
      borderTop: `1px solid ${token.colorBgContainerDisabled}`,
    },
  };

  const modalProps: ModalProps = {
    ...(useCommonProps && ModalCommonProps),
    ...restConfig,
    styles: {
      ...(useCustomStyles && customStyles),
      ...styles,
    },
    open: isOpen,
    onCancel(e) {
      if (restConfig.onCancel) {
        restConfig.onCancel(e);
      }
      closeModal();
    },
    onClose(e) {
      if (restConfig.onClose) {
        restConfig.onClose(e);
      }
      closeModal();
    },
  };

  return {
    isModalOpen: isOpen,
    showModal,
    closeModal,
    modalProps,
  };
};
