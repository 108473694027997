import { Input } from "antd";
import { useWatch } from "antd/es/form/Form";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { NamePath } from "antd/es/form/interface";
import { FC } from "react";

type Props = {
  name: NamePath;
}

const CodeInputFormItem: FC<Props> = (props) => {
  const { name } = props;
  const form = useFormInstance();

  const currentValue = useWatch(name, { form, preserve: true });

  const handleOnChange = (value: string) => {
    form.setFieldValue(name, value);
  };

  return (
    <Input
      value={currentValue}
      onChange={e => handleOnChange(e.currentTarget.value)}
    />
  );
};

export default CodeInputFormItem;
