import { SorterResult } from "antd/es/table/interface";

export interface IDataColumn<CodeType = string> {
  id: number;
  title: string;
  description: string;
  code: CodeType;
  order: number;
  width: number;
  hidden: boolean;
  type: IDataColumnType;
  required: boolean;
  class: IDataColumnClass;
  precision: number;
  currency?: string;
  private: boolean;
  options: IDataColumnOption[];
  dateFormat: IDataColumnDateFormat;
  timeFormat: IDataColumnTimeFormat;
  enableCodes: boolean;
}

export enum DataColumnDateFormats {
  Default = "default",
  Friendly = "friendly",
  Usa = "usa",
  Europe = "europe",
  Iso = "iso",
}

export type IDataColumnDateFormat = DataColumnDateFormats;

export enum DataColumnTimeFormats {
  Usa = "usa",
  Europe = "europe",
  None = "none",
}

export type IDataColumnTimeFormat = DataColumnTimeFormats;

export enum DataColumnTypes {
  SingleLineText = "SingleLineText",
  MultiLineText = "MultiLineText",
  SingleSelect = "SingleSelect",
  MultiSelect = "MultiSelect",
  Number = "Number",
  Currency = "Currency",
  Percent = "Percent",
  Formula = "Formula",
  Boolean = "Boolean",
  User = "User",
  Users = "Users",
  Date = "Date",
  DateTime = "DateTime",
  File = "File",
  Files = "Files",
  Reference = "Reference"
}

export type IDataColumnType = DataColumnTypes;

export enum DataColumnClasses {
  System = "system",
  Default = "default",
  Mandatory = "mandatory",
  Custom = "custom",
}

export type IDataColumnClass = DataColumnClasses;

export interface IDataColumnOption {
  id: number;
  title: string;
  abbreviation: string;
  icon: string;
  color: string;
  order: number;
  group?: string | null;
  description: string;
}

export interface IDataView {
  id: number;
  uid: string;
  title: string;
  type: IDataViewType;
  icon: string;
  order: number;
  createdAt: Date;
  updatedAt: Date;
  description: string;
  shared: boolean;
  // FIXME: provide proper types
  filterConfig: Record<string, unknown> | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sortConfig: SorterResult<any> | null;
  columnsConfig: IDataViewColumnsConfig | null;
}

export interface IDataViewColumnsConfig {
  // FIXME: Provide a common type rather than a partial
  columns?: Array<Partial<IDataColumn>> | null;
}

export enum DataViewTypes {
  Table = "table",
  Form = "form"
}

export type IDataViewType = DataViewTypes;
