import AppLogo from "components/AppLogo";
import UserSettingsDropdown from "components/UserSettingsDropdown";
import { FC, PropsWithChildren } from "react";
import {
  AtskAppRoot,
  AtskFullPage,
  AtskGlobalNavbar,
  AtskMain,
  AtskMainContent,
  AtskSidebar
} from "./styled";

interface AppLayoutProps {
  showNavbar?: boolean;
}

const AppLayout: FC<PropsWithChildren<AppLayoutProps>> = (props) => {
  const { showNavbar = false, children } = props;

  return (
    <AtskAppRoot>
      <AtskFullPage>
        {showNavbar && (
          <AtskGlobalNavbar>
            <AppLogo />
            <UserSettingsDropdown />
          </AtskGlobalNavbar>
        )}
        <AtskMain>
          <AtskSidebar />
          <AtskMainContent>
            {children}
          </AtskMainContent>
        </AtskMain>
      </AtskFullPage>
    </AtskAppRoot>
  );
};

export default AppLayout;
