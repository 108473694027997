import { FC, memo } from "react";
import TagCell from "../TableCell/Tag";
import { EditableTableFormItemComponent } from "./lib";
import { IBaseEntity } from "types/entity";
import EmptyCell from "../TableCell/Empty";

const Reference: FC<EditableTableFormItemComponent<IBaseEntity>> = (props) => {
  const { value } = props;

  return (
    <>
      {value?.title
        ? <TagCell text={value?.title} />
        : <EmptyCell />
      }
    </>
  );
};

export default memo(Reference);
