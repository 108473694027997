import { HTMLAttributes, forwardRef } from "react";

const IconWrap = forwardRef<HTMLSpanElement, HTMLAttributes<HTMLSpanElement>>((props, ref) => {
  return (
    <span
      role="img"
      className="anticon"
      ref={ref}
      {...props}
    >
      {props.children}
    </span>
  );
});

IconWrap.displayName = "IconWrap";

export default IconWrap;
