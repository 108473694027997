import styled from "@emotion/styled";

export const IconPickerFormItemGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 7px;
  grid-row-gap: 7px;
  .ant-card {
    border-radius: var(--ant-border-radius-sm);
  }
`;

export const ColorPickerFormItemGrid = styled(IconPickerFormItemGrid)`
  .ant-card {
    border-radius: 100px;
  }
`;
