import { FC } from "react";

interface ConditionalWrapperProps {
  condition: boolean;
  wrapper: IConditionalWrapperFn;
  children: JSX.Element;
}

export interface IConditionalWrapperFn {
  (children: JSX.Element): JSX.Element;
}

const ConditionalWrapper: FC<ConditionalWrapperProps> = ({ condition, wrapper, children }) => condition
  ? wrapper(children)
  : children;

export default ConditionalWrapper;
