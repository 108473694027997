import {
  FontColorsOutlined,
  FontSizeOutlined,
  DownCircleOutlined,
  NumberOutlined,
  PercentageOutlined,
  FieldBinaryOutlined,
  UserOutlined,
  CalendarOutlined,
  FileTextOutlined,
  FunctionOutlined,
  TeamOutlined
} from "@ant-design/icons";
import { AntdIconProps } from "@ant-design/icons/lib/components/AntdIcon";
import { FormItemProps } from "antd";
import { FC } from "react";
import { IDataColumn, IDataColumnType, DataColumnTypes, DataColumnClasses } from "types/data-component";
import ReferenceOutlined from "icons/reference-outlined.tsx";

export const TableColumnWidth = 175;

export interface TableColumnStatus {
  text: string;
  color: string;
  icon: JSX.Element;
}

export const getTableColumnIcon = (columnType: IDataColumn["type"]) => {
  const iconsMap: Record<IDataColumnType, FC<AntdIconProps>> = {
    [DataColumnTypes.Formula]: FunctionOutlined,
    [DataColumnTypes.SingleLineText]: FontColorsOutlined,
    [DataColumnTypes.MultiLineText]: FontSizeOutlined,
    [DataColumnTypes.SingleSelect]: DownCircleOutlined,
    [DataColumnTypes.MultiSelect]: DownCircleOutlined,
    [DataColumnTypes.Number]: NumberOutlined,
    [DataColumnTypes.Currency]: NumberOutlined,
    [DataColumnTypes.Percent]: PercentageOutlined,
    [DataColumnTypes.Boolean]: FieldBinaryOutlined,
    [DataColumnTypes.User]: UserOutlined,
    [DataColumnTypes.Users]: TeamOutlined,
    [DataColumnTypes.Date]: CalendarOutlined,
    [DataColumnTypes.DateTime]: CalendarOutlined,
    [DataColumnTypes.File]: FileTextOutlined,
    [DataColumnTypes.Files]: FileTextOutlined,
    [DataColumnTypes.Reference]: ReferenceOutlined,
  };

  return iconsMap[columnType] ?? FontColorsOutlined;
};

export const EditableColumnClasses = [
  DataColumnClasses.Custom,
  DataColumnClasses.Default,
  DataColumnClasses.Mandatory,
];

export const getTableFormItemProps = (column?: Partial<IDataColumn>, message = "") => {
  const editable = EditableColumnClasses.includes(column?.class as IDataColumn["class"]);

  const name: FormItemProps["name"] = column?.code;

  const required: FormItemProps["required"] = column?.required;

  // TODO: check value types
  const rules: FormItemProps["rules"] = column?.required
    ? [{
      validator: (_, value) => {
        return value
          ? Promise.resolve()
          : Promise.reject(new Error(message));
      }
    }]
    : undefined;

  const valuePropName: FormItemProps["valuePropName"] = column?.type === DataColumnTypes.Boolean
    ? "checked"
    : undefined;

  const props: FormItemProps & { editable: boolean } = {
    editable,
    name,
    rules,
    required,
    valuePropName,
  };

  return props;
};

export const isTableFieldEditable = (columnClass: IDataColumn["class"]) => {
  const isEditable = EditableColumnClasses.includes(columnClass);
  return isEditable;
};


export const getEditableFields = (fields: Array<IDataColumn> = []) => {
  return fields.filter(field => isTableFieldEditable(field.class));
};

export const getRequiredFields = (fields: Array<IDataColumn> = []) => {
  return fields.filter(field => !!field.required);
};

 
export const getColumnFieldType = (type: DataColumnTypes, code?: string): string => {
  // TODO: support i18n
  const fieldsNameMap: Record<DataColumnTypes, string> = {
    [DataColumnTypes.SingleLineText]: "Single Line Text",
    [DataColumnTypes.MultiLineText]: "Multi Line Text",
    [DataColumnTypes.SingleSelect]: "Single Select",
    [DataColumnTypes.MultiSelect]: "Multiple Select",
    [DataColumnTypes.Number]: "Number",
    [DataColumnTypes.Currency]: "Currency",
    [DataColumnTypes.Percent]: "Percent",
    [DataColumnTypes.Formula]: "Formula",
    [DataColumnTypes.Boolean]: "Boolean",
    [DataColumnTypes.User]: "User",
    [DataColumnTypes.Users]: "Users",
    [DataColumnTypes.Date]: "Date",
    [DataColumnTypes.DateTime]: "DateTime",
    [DataColumnTypes.File]: "File",
    [DataColumnTypes.Files]: "Files",
    [DataColumnTypes.Reference]: "Reference",
  };

  // TODO: @sergey define logic (use column.code and column.type as needed)
  const fieldsCodeMap: Record<string, string> = {
    // TODO: extract codes to enum
    "status": "Status",
  };

  const titleByCode = code ? fieldsCodeMap[code] : undefined;
  const titleByType = fieldsNameMap[type];

  const columnTypeTitle = titleByCode || titleByType;
  return columnTypeTitle;
};
