import { Checkbox } from "antd";
import { FC, memo } from "react";
import { EditableTableFormItemComponent } from "./lib";
import { TableDataWrap } from "components/DataTable/SimpleTable/FormItems/styled";

const Boolean: FC<EditableTableFormItemComponent> = (props) => {
  const { checked, onChange } = props;
  const formHandlers = { checked, onChange };

  return (
    <TableDataWrap>
      <Checkbox {...formHandlers} />
    </TableDataWrap>
  );
};

export default memo(Boolean);
