import { FormInstance } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useModal, UseModalConfig } from "./useModal";

interface UseModalFormConfig<T = unknown> extends UseModalConfig {
  formProps?: FormInstance<T>;
}

export const useModalForm = <T = object>(config?: UseModalFormConfig) => {
  const { formProps = undefined, ...modalProps } = config ?? {};
  const modalHook = useModal(modalProps);
  const [form] = useForm<T>(formProps as FormInstance<T>);

  return {
    ...modalHook,
    form,
  };
};
