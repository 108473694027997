import { ReactNode } from "@tanstack/react-router";
import { Select } from "antd";
import { FC, memo } from "react";
import TagCell from "../TableCell/Tag";
import { EditableTableFormItemComponent, SelectFormItemProps } from "./lib";

const MultiSelect: FC<EditableTableFormItemComponent<Array<number>>> = (props) => {
  const { column, value, onChange } = props;

  const selectOptions = column.options.filter(opt => value?.includes(opt.id));
  const selectValues = selectOptions.map(opt => opt.id) ?? [];
  const formHandlers = { value: selectValues, onChange };

  return (
    <Select<number[]>
      showSearch={false}
      mode="multiple"
      tagRender={(tag) => <TagCell text={(tag.label as ReactNode).props?.text} color={(tag.label as ReactNode).props?.color} />}
      suffixIcon={null}
      {...formHandlers}
      {...SelectFormItemProps}
      style={{ minWidth: 132 }}
    >
      {column.options.map(option => (
        <Select.Option key={option.id} value={option.id}>
          <TagCell text={option.title} color={option.color} />
        </Select.Option>
      ))}
    </Select>
  );
};

export default memo(MultiSelect);
