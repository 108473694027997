import IconWrap from "icons/IconWrap";
import { AntLikeIconProps } from "lib/utils/antIcon";
import { forwardRef, ForwardRefExoticComponent } from "react";

export const CircleOutlined: ForwardRefExoticComponent<AntLikeIconProps> = forwardRef<HTMLSpanElement, AntLikeIconProps>((props, ref) => {
  return (
    <IconWrap {...props} ref={ref} >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 896 896"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M448 823C655.107 823 823 655.107 823 448C823 240.893 655.107 73 448 73C240.893 73 73 240.893 73 448C73 655.107 240.893 823 448 823ZM448 748C613.685 748 748 613.685 748 448C748 282.315 613.685 148 448 148C282.315 148 148 282.315 148 448C148 613.685 282.315 748 448 748Z"
        />
      </svg>
    </IconWrap>
  );
});

CircleOutlined.displayName = "CircleOutlined";

export default CircleOutlined;
