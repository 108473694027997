import styled, { CreateStyled } from "@emotion/styled";
import { css } from "@emotion/react";
import { UseBreakpointsState } from "lib/hooks/useBreakpoints";
import { FC } from "react";

export const transientOptions: Parameters<CreateStyled>[1] = {
  shouldForwardProp: (propName: string) => !propName.startsWith("$"),
};

export const StyledEmotionInterop = (component: FC, template: string) => {
  return styled(component, transientOptions)(template);
};

export const BarContainerSecondary = styled.div<{ breakpoints?: UseBreakpointsState }>`
  background-color: var(--ant-color-bg-container);
  padding: 16px 50px;
  ${({ breakpoints }) => breakpoints?.isTablet && css`
    padding: 16px;
  `}
`;

export const BodyScrollable = styled.div`
  flex-grow: 1;
  overflow: hidden;
  margin-bottom: 50px;
`;

export const TableWrap = styled.div`
  th.ant-table-cell {
    padding: 0 !important;
    font-size: 14px;
    font-weight: 400 !important;
  }
`;

export const TabsWrap = styled.div<{ breakpoints?: UseBreakpointsState }>`
  ${({ breakpoints }) => breakpoints?.isDesktop && css`
    .ant-tabs-nav {
      padding: 0 50px;
    }
  `}
  ${({ breakpoints }) => breakpoints?.isTablet && css`
    .ant-tabs-nav {
      padding: 0 16px;
    }
  `}
`;

// TODO: @boris to see if forms have styling options
export const FormWrap = styled.div<{ labelColor?: string }>`
  label {
    font-size: 13px !important;
    font-weight: 500;
    ${({ labelColor }) => labelColor && css`
      color: ${labelColor} !important;
    `}
  }
  .ant-col.ant-form-item-label {
    padding-bottom: 4px !important;
  }
  .ant-form-item {
    margin-bottom: 14px !important;
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
`;
