import { Button, Flex, Result } from "antd";
import { FC } from "react";

type Props = {
  error: Error;
}

const ErrorBoundary: FC<Props> = (props) => {
  const { error } = props;
  return (
    <Flex justify="center" align="center" style={{ paddingTop: 150 }}>
      <Result
        status="error"
        title="Oops! Something went wrong"
        subTitle={error.message || "An unexpected error occurred."}
        extra={
          <Button type="primary" onClick={() => window.location.reload()}>
            Reload app
          </Button>
        }
      />
    </Flex>
  );
};

export default ErrorBoundary;
