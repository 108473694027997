import { FC, memo } from "react";
import { EditableTableFormItemComponent } from "./lib";
import { Avatar } from "antd";
import { TableDataWrap } from "components/DataTable/SimpleTable/FormItems/styled";
import { IUser } from "types/user";
import { getUserInitials, getUserProfilePicture } from "lib/utils/user";

const User: FC<EditableTableFormItemComponent<IUser>> = (props) => {
  const { value } = props;

  return (
    <TableDataWrap>
      <Avatar
        size="small"
        src={getUserProfilePicture(value)}
      >
        {getUserInitials(value)}
      </Avatar>
    </TableDataWrap>
  );
};

export default memo(User);
