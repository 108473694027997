import { DatePicker, Input, InputNumber, Select } from "antd";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const TableDataWrap = styled.div`
  margin: 0 7px;
`;

// Reusable CSS as a function
export const borderHoverable = ({ hoverBorderColor }: { hoverBorderColor?: string }) => css`
  border-color: transparent;
  &:hover {
    border-color: ${hoverBorderColor || "defaultColor"};
  }
`;

// Styled components
export const TableDatePicker = styled(DatePicker) <{ hoverBorderColor?: string }>`
  ${({ hoverBorderColor }) => borderHoverable({ hoverBorderColor })};
  width: 100%;
`;

export const TableInput = styled(Input) <{ hoverBorderColor?: string }>`
  ${({ hoverBorderColor }) => borderHoverable({ hoverBorderColor })};
`;

export const TableInputNumber = styled(InputNumber) <{ hoverBorderColor?: string }>`
  ${({ hoverBorderColor }) => borderHoverable({ hoverBorderColor })};
  width: 100%;
`;

export const TableSelect = styled(Select) <{ hoverBorderColor?: string }>`
  ${({ hoverBorderColor }) => borderHoverable({ hoverBorderColor })};
`;
