import { getRouteApi, Outlet } from "@tanstack/react-router";
import AccountsNavbar from "components/AccountsNavbar";
import AccountSwitcher from "components/AccountSwitcher";
import AppLayout from "components/AppLayout";
import LoadingSkeleton from "components/LoadingSkeleton";
import NotFoundOrForbidden from "components/NotFoundOrForbidden";
import { AccountProvider } from "lib/context/AccountContext";
import { FC } from "react";
import { useGetAccountDetailsQuery } from "redux/services/accountsApi";

const LayoutAccount: FC = () => {
  const route = getRouteApi("/account/$id");
  const { id }: { id: string } = route.useParams();

  const accountQuery = useGetAccountDetailsQuery(id);
  const { data, isLoading, isSuccess, error } = accountQuery;
  // TODO: @sergey style loading and error states
  return (
    <AppLayout showNavbar>
      {isLoading &&
        <LoadingSkeleton />
      }
      {error &&
        <NotFoundOrForbidden />
      }
      {isSuccess && data &&
        <AccountProvider value={accountQuery}>
          <AccountSwitcher currentKey={id} />
          <AccountsNavbar />
          <Outlet />
        </AccountProvider>
      }
    </AppLayout>
  );
};

export default LayoutAccount;
