import { Card, Flex, theme } from "antd";
import { useWatch } from "antd/es/form/Form";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { NamePath } from "antd/es/form/interface";
import { ColorPickerFormItemGrid } from "components/FormColorIconPicker/styled";
import { ColorsPalette } from "lib/utils/colors";
import { FC } from "react";

type Props = {
  name: NamePath;
  colors: ColorsPalette;
};

const ColorPickerFormItem: FC<Props> = (props) => {
  const { name, colors } = props;

  const { token } = theme.useToken();
  const form = useFormInstance();
  const selectedColor = useWatch(name, { form, preserve: true });

  const handleColorClick = (color: string) => {
    form.setFieldValue(name, color);
  };

  return (
    <ColorPickerFormItemGrid>
      {Object.entries(colors).map(([key, color]) => (
        <Flex
          key={key}
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Card
            onClick={() => handleColorClick(key)}
            style={{
              ...(selectedColor === key && { borderColor: token.colorPrimary }),
            }}
            styles={{
              body: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 20,
                height: 20,
                padding: 0,
                cursor: "pointer",
              },
            }}
          >
            <div
              style={{
                width: 14,
                height: 14,
                borderRadius: 7,
                backgroundColor: color.primary,
              }}
            />
          </Card>
        </Flex>
      ))}
    </ColorPickerFormItemGrid>
  );
};

export default ColorPickerFormItem;
