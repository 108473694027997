import { ModalProps } from "antd";
import { getTr } from "lib/i18n/getTr";


export const ModalCommonProps: ModalProps = {
  maskClosable: false,
  keyboard: false,
  centered: true,
  okText: getTr("general.Confirm"),
  cancelText: getTr("general.Cancel"),
  // Source: https://github.com/ant-design/ant-design/issues/31513
  zIndex: 1060,
};
