import { TableInput } from "components/DataTable/SimpleTable/FormItems/styled";
import { debounce } from "lib/utils/data";
import { FC, memo, useCallback } from "react";
import EmptyCell from "../TableCell/Empty";
import { ReadOnlyField } from "../TableCell/styled";
import { EditableTableFormItemComponent } from "./lib";

const SingleLineText: FC<EditableTableFormItemComponent<string>> = (props) => {
  const { value, onChange, editable } = props;

  const handleBlur = useCallback(
    debounce((event: React.FocusEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event);
      }
    }),
    [onChange]
  );

  const renderEditable = (
    <TableInput
      size="small"
      placeholder="Empty"
      defaultValue={value}
      onBlur={handleBlur}
    />
  );

  const renderReadOnly = value ? (
    <ReadOnlyField>{value}</ReadOnlyField>
  ) : (
    <EmptyCell />
  );

  return <>{editable ? renderEditable : renderReadOnly}</>;
};

export default memo(SingleLineText);
