import { sortCollection } from "lib/utils/data";
import { IDataColumn, IDataView } from "types/data-component";
import { IEntityId, ISortPayload } from "types/entity";
import { JSendResponse } from "types/jsend";
import { IList, IListListItem } from "types/list";
import { rootApi } from "./root";

export const listsApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getLists: builder.query<Array<IListListItem>, { spaceId: IEntityId }>({
      query: ({ spaceId }) => ({
        url: `v2/spaces/${spaceId}/lists`,
        method: "GET",
      }),
      providesTags: ["spaceLists"]
    }),
    getListDetailed: builder.query<IList, { spaceId: IEntityId, listId: IEntityId }>({
      query: ({ spaceId, listId }) => ({
        url: `v2/spaces/${spaceId}/lists/${listId}`,
        method: "GET",
      }),
      transformResponse: (response: IList) => ({
        ...response,
        columns: sortCollection(response.columns),
        views: sortCollection(response.views),
      }),
      providesTags: ["spaceListDetails"]
    }),
    createList: builder.mutation<JSendResponse, { spaceId: IEntityId, payload: Partial<IList> }>({
      query: ({ spaceId, payload }) => ({
        url: `v2/spaces/${spaceId}/lists`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["spaceLists", "spaceListDetails"]
    }),
    modifyList: builder.mutation<JSendResponse, { spaceId: IEntityId, listId: IEntityId, payload: Partial<IList> }>({
      query: ({ spaceId, listId, payload }) => ({
        url: `v2/spaces/${spaceId}/lists/${listId}`,
        method: "PUT",
        body: payload,
      }),
      async onQueryStarted({ spaceId, listId, payload }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          listsApi.util.updateQueryData("getListDetailed", { spaceId, listId }, (draft) => {
            if (draft) {
              Object.assign(draft, payload);
            }
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: ["spaceLists"]
    }),
    deleteList: builder.mutation<JSendResponse, { spaceId: IEntityId, listId: IEntityId }>({
      query: ({ spaceId, listId }) => ({
        url: `v2/spaces/${spaceId}/lists/${listId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["spaceLists", "spaceListDetails"]
    }),
    addListColumn: builder.mutation<JSendResponse, { spaceId: IEntityId, listId: IEntityId, payload: Partial<IDataColumn> }>({
      query: ({ spaceId, listId, payload }) => ({
        url: `v2/spaces/${spaceId}/lists/${listId}/columns`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["spaceListDetails"]
    }),
    modifyListColumn: builder.mutation<JSendResponse, { spaceId: IEntityId, listId: IEntityId, columnId: IEntityId, payload: Partial<IDataColumn> }>({
      query: ({ spaceId, listId, columnId, payload }) => ({
        url: `v2/spaces/${spaceId}/lists/${listId}/columns/${columnId}`,
        method: "PUT",
        body: payload,
      }),
      async onQueryStarted({ spaceId, listId, columnId, payload }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          listsApi.util.updateQueryData("getListDetailed", { spaceId, listId }, (draft) => {
            if (draft) {
              const column = draft.columns.find((col) => col.code === columnId);
              if (column) {
                // Merge payload with existing column
                Object.assign(column, payload);
              }
            }
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    deleteListColumn: builder.mutation<JSendResponse, { spaceId: IEntityId, listId: IEntityId, columnId: IEntityId }>({
      query: ({ spaceId, listId, columnId }) => ({
        url: `v2/spaces/${spaceId}/lists/${listId}/columns/${columnId}`,
        method: "DELETE",
      }),
      async onQueryStarted({ spaceId, listId, columnId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          listsApi.util.updateQueryData("getListDetailed", { spaceId, listId }, (draft) => {
            if (draft) {
              // Filter out the deleted column
              draft.columns = draft.columns.filter((col) => col.code !== columnId);
            }
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    reorderListColumns: builder.mutation<JSendResponse, { spaceId: IEntityId, listId: IEntityId, payload: ISortPayload }>({
      query: ({ spaceId, listId, payload }) => ({
        url: `v2/spaces/${spaceId}/lists/${listId}/columns`,
        method: "PATCH",
        body: payload,
      }),
      async onQueryStarted({ spaceId, listId, payload }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          listsApi.util.updateQueryData("getListDetailed", { spaceId, listId }, (draft) => {
            if (draft) {
              draft.columns.forEach((column) => {
                const updatedColumn = payload.find((c) => c.id === column.id);
                if (updatedColumn) {
                  column.order = updatedColumn.order;
                }
              });

              draft.columns.sort((a, b) => a.order - b.order);
            }
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    addListView: builder.mutation<JSendResponse<IDataView>, { spaceId: IEntityId, listId: IEntityId, payload: Partial<IDataView> }>({
      query: ({ spaceId, listId, payload }) => ({
        url: `v2/spaces/${spaceId}/lists/${listId}/views`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["spaceListDetails"]
    }),
    modifyListView: builder.mutation<JSendResponse, { spaceId: IEntityId, listId: IEntityId, viewId: IEntityId, payload: Partial<IDataView> }>({
      query: ({ spaceId, listId, viewId, payload }) => ({
        url: `v2/spaces/${spaceId}/lists/${listId}/views/${viewId}`,
        method: "PUT",
        body: payload,
      }),
      async onQueryStarted({ spaceId, listId, viewId, payload }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          listsApi.util.updateQueryData("getListDetailed", { spaceId, listId }, (draft) => {
            if (draft) {
              const view = draft.views.find((v) => v.uid === viewId);
              if (view) {
                // Merge payload with existing view
                Object.assign(view, payload);
              }
            }
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    deleteListView: builder.mutation<JSendResponse, { spaceId: IEntityId, listId: IEntityId, viewId: IEntityId }>({
      query: ({ spaceId, listId, viewId }) => ({
        url: `v2/spaces/${spaceId}/lists/${listId}/views/${viewId}`,
        method: "DELETE",
      }),
      async onQueryStarted({ spaceId, listId, viewId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          listsApi.util.updateQueryData("getListDetailed", { spaceId, listId }, (draft) => {
            if (draft) {
              // Filter out the deleted column
              draft.views = draft.views.filter((v) => v.uid !== viewId);
            }
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    reorderListViews: builder.mutation<JSendResponse, { spaceId: IEntityId, listId: IEntityId, payload: ISortPayload }>({
      query: ({ spaceId, listId, payload }) => ({
        url: `v2/spaces/${spaceId}/lists/${listId}/views`,
        method: "PATCH",
        body: payload,
      }),
      async onQueryStarted({ spaceId, listId, payload }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          listsApi.util.updateQueryData("getListDetailed", { spaceId, listId }, (draft) => {
            if (draft) {
              draft.views.forEach((item) => {
                const updatedItem = payload.find((c) => c.id === item.id);
                if (updatedItem) {
                  item.order = updatedItem.order;
                }
              });

              draft.views.sort((a, b) => a.order - b.order);
            }
          })
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

export const {
  useGetListsQuery, useLazyGetListsQuery,
  useGetListDetailedQuery, useLazyGetListDetailedQuery,
  useCreateListMutation,
  useModifyListMutation,
  useDeleteListMutation,
  useAddListColumnMutation,
  useModifyListColumnMutation,
  useDeleteListColumnMutation,
  useReorderListColumnsMutation,
  useAddListViewMutation,
  useModifyListViewMutation,
  useDeleteListViewMutation,
  useReorderListViewsMutation,
} = listsApi;
