import { Button, Upload } from "antd";
import { FC, memo } from "react";
import { EditableTableFormItemComponent } from "./lib";
import { UploadOutlined } from "@ant-design/icons";
import { TableDataWrap } from "components/DataTable/SimpleTable/FormItems/styled";
import { IFile } from "types/helpers";

const Files: FC<EditableTableFormItemComponent<Array<IFile>>> = () => {
  return (
    <TableDataWrap>
      <Upload multiple>
        <Button size="small" icon={<UploadOutlined />} />
      </Upload>
    </TableDataWrap>
  );
};

export default memo(Files);
