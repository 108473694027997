import { getRouteApi, Link } from "@tanstack/react-router";
import { Breadcrumb, Button, Flex, Input } from "antd";
import DataList from "components/DataList";
import LoadingSkeleton from "components/LoadingSkeleton";
import NotFoundOrForbidden from "components/NotFoundOrForbidden";
import { useListContext } from "lib/context/ListContext";
import { useSpaceContext } from "lib/context/SpaceContext";
import useBreakpoints from "lib/hooks/useBreakpoints";
import { FC } from "react";
import { handleApiFormRequest } from "redux/helpers";
import { useGetRecordDetailedQuery, useSetRecordPropertiesMutation } from "redux/services/recordsApi";

const Record: FC = () => {
  const breakpoints = useBreakpoints();
  const routeApi = getRouteApi("/s/$id/l/$listId/r/$recordId");
  const { recordId } = routeApi.useParams();

  const { data: space } = useSpaceContext();
  const { data: list } = useListContext();
  const { data: record, isLoading, isSuccess, error } = useGetRecordDetailedQuery({
    spaceId: space.uid,
    listId: list.uid,
    recordId: recordId,
  });

  const [setPropertiesFn] = useSetRecordPropertiesMutation();

  const handleOnPatch = (data: Record<string, unknown>) => {
    return handleApiFormRequest(
      setPropertiesFn({
        spaceId: space.uid,
        listId: list.uid,
        recordId: recordId,
        properties: data,
      }).unwrap
    );
  };

  return (
    <>
      {isLoading &&
        <LoadingSkeleton />
      }
      {error &&
        <NotFoundOrForbidden />
      }
      {isSuccess && record &&
        <Flex justify="center">
          <Flex
            vertical
            style={{
              width: "100%",
              padding: "18px 50px",
              ...(breakpoints.isTablet && { padding: "18px" }),
            }}
          >
            <Flex gap="middle" vertical>
              <Breadcrumb
                items={[
                  { title: <Link to="/s/$id">Home</Link> },
                  { title: <Link to="/s/$id/l">Lists</Link> },
                  { title: <Link to="/s/$id/l/$listId" params={{ listId: list.uid }}>{list.title}</Link> },
                  { title: record.title },
                ]}
              />
              <Flex>
                <Button size="small">
                  {record?.uid}
                </Button>
              </Flex>
              <Input
                value={record.title}
                style={{ fontSize: 21, fontWeight: 700 }}
              />
              <DataList
                item={record}
                fields={list.columns}
                onPatch={handleOnPatch}
              />
            </Flex>
          </Flex>
        </Flex>
      }
    </>
  );
};

export default Record;
