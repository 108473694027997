import { Select, Typography } from "antd";
import { FC, memo } from "react";
import TagCell from "../TableCell/Tag";
import { EditableTableFormItemComponent, SelectFormItemProps } from "./lib";
import { getAntIconComponent } from "lib/utils/antIcon.ts";

const SingleSelect: FC<EditableTableFormItemComponent<number>> = (props) => {
  const { column, value, onChange } = props;

  const selectOption = column.options.find(opt => String(opt.id) === String(value));
  const selectValue = (selectOption) ? (selectOption.id) : null;
  const formHandlers = { value: selectValue, onChange };

  return (
    <Select<number>
      suffixIcon={null}
      {...SelectFormItemProps}
      {...formHandlers}
      dropdownStyle={{ minWidth: 200 }}
    >
      {!column.required &&
        <Select.Option key="empty" value={null}>
          <Typography.Text style={{ color: "var(--ant-color-text-quaternary)" }}>
            Empty
          </Typography.Text>
        </Select.Option>
      }
      {column.options.map(option => {
        const IconComponent = getAntIconComponent(option.icon);
        return (
          <Select.Option key={option.id} value={option.id}>
            <TagCell text={option.title} color={option.color} icon={<IconComponent />} />
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default memo(SingleSelect);
