import { DownloadOutlined } from "@ant-design/icons";
import { Button, Drawer, Empty } from "antd";
import ApryseViewer from "components/ApryseViewer";
import { isApryseViewerCompatibleFormat } from "components/ApryseViewer/lib";
import { useDrawer } from "lib/hooks/antd-sunflower";
import { downloadFile, formatBytes } from "lib/utils/file";
import { cloneElement, FC, ReactElement, ReactNode } from "react";
import { IFile } from "types/helpers";

type Props = {
  document?: IFile;
  triggerElement?: ReactNode;
}

const FileViewerDrawer: FC<Props> = (props) => {
  const { document, triggerElement } = props;

  const isViewerAvailable = isApryseViewerCompatibleFormat(document?.ext);
  const { showDrawer, drawerProps } = useDrawer({
    width: "100vw",
    height: "100vh",
    styles: {
      body: {
        padding: 0,
        overflow: "hidden",
      }
    }
  });

  return (
    <>
      {triggerElement
        ? cloneElement(triggerElement as ReactElement, { onClick: showDrawer })
        : <Button onClick={showDrawer}>Open</Button>
      }
      <Drawer
        {...drawerProps}
      >
        {document &&
          <>
            {isViewerAvailable
              ? (
                <ApryseViewer
                  webViewerOptions={{
                    initialDoc: document.url,
                    disabledElements: ["default-top-header"],
                  }}
                />
              )
              : (
                <center>
                  <p>No preview available</p>
                  <p><strong>{document.name}</strong> ({formatBytes(document.size * 1_000)})</p>
                  <a href={document.url} target="_blank" rel="noopener noreferrer">Open the doc</a><br />
                  <Button
                    onClick={() => downloadFile(document.url, document.name)}
                    icon={<DownloadOutlined />}
                  >
                    Download
                  </Button>
                </center>
              )
            }
          </>
        }
        {!document &&
          <Empty description="No data provided" />
        }
      </Drawer>
    </>
  );
};

export default FileViewerDrawer;
