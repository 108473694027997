import * as Icons from "@ant-design/icons";
import { AntdIconProps } from "@ant-design/icons/lib/components/AntdIcon";
import { FC, forwardRef, ForwardRefExoticComponent, RefAttributes } from "react";

export type AntIconName = keyof typeof Icons;

export type AntLikeIconProps = Omit<AntdIconProps, "ref"> & RefAttributes<HTMLSpanElement>;

export const EmptyIcon: ForwardRefExoticComponent<AntLikeIconProps> = forwardRef<HTMLSpanElement, AntLikeIconProps>(() => {
  return null;
});

EmptyIcon.displayName = "EmptyIcon";

export const AntIconFallback = EmptyIcon;

export const getAntIconComponent = (iconName?: AntIconName | string, fallback = AntIconFallback): FC<AntdIconProps> => {
  const IconComponent = Icons[iconName as AntIconName];
  return (IconComponent as FC<AntdIconProps>) ?? fallback;
};
