import { createContext, FC, PropsWithChildren, useContext } from "react";
import { IList } from "types/list";

interface ListContextProps {
  data: IList;
}

interface ListProviderProps {
  data: IList;
}

const ListContext = createContext<ListContextProps>({
  data: undefined!,
});

export const useListContext = () => useContext(ListContext);

export const ListProvider: FC<PropsWithChildren<ListProviderProps>> = (props) => {
  const { children, ...data } = props;
  return (
    <ListContext.Provider value={data}>
      {children}
    </ListContext.Provider>
  );
};
