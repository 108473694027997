import { ColumnManagerDateFormatOptions, ColumnManagerDefaults } from "components/ColumnManagerModal/lib";
import { TableDatePicker } from "components/DataTable/SimpleTable/FormItems/styled";
import { processDate } from "lib/utils/date";
import { FC, memo } from "react";
import { EditableTableFormItemComponent } from "./lib";

const Date: FC<EditableTableFormItemComponent<string>> = (props) => {
  const { column, value, onChange } = props;
  const formHandlers = { value: processDate(value), onChange };
  const format = ColumnManagerDateFormatOptions[column.dateFormat]?.formatter ?? ColumnManagerDefaults.dateFormat;

  return (
    <TableDatePicker
      allowClear
      size="small"
      placeholder="Empty"
      format={format}
      {...formHandlers}
    />
  );
};

export default memo(Date);
