import { SortAscendingOutlined, SortDescendingOutlined } from "@ant-design/icons";
import { Flex, theme, Tooltip } from "antd";
import { SorterResult } from "antd/es/table/interface";
import ConditionalWrapper from "components/ConditionalWrapper";
import { Sorts } from "components/DataTable/lib";
import { PropertyTitleWrap } from "components/DataTable/SimpleTable/PropertyTitle/styled";
import { MoveDirection } from "lib/utils/data";
import { ElementType, FC } from "react";
import { IDataColumn } from "types/data-component";
import ContextMenu from "./PropertyTitleContextMenu";

export interface TableHeaderProps {
  icon?: ElementType;
  title?: string;
  tooltip?: string;
  showContextMenu?: boolean;
  dataColumn?: IDataColumn;
  sorted?: SorterResult["order"];
  onSort?: (data: Sorts<unknown>) => void;
  onClick?: () => void;
  onDelete?: () => void;
  onModify?: (data: Partial<IDataColumn>) => void;
  onMove?: (direction: MoveDirection) => void;

  // TODO: @sergey to unify styles
  pointer?: boolean;
  detailedPage?: boolean;
  formView?: boolean;
}

const PropertyTitle: FC<TableHeaderProps> = (props) => {
  const {
    icon: IconElement,
    title,
    tooltip = "",
    showContextMenu,
    dataColumn,
    onClick,
    onDelete,
    onModify,
    onMove,
    onSort,
    sorted,
    pointer,
    detailedPage,
    formView,
  } = props;

  const { token } = theme.useToken();
  const { colorTextSecondary, colorErrorText } = token;

  return (
    <ConditionalWrapper
      condition={!!showContextMenu && !!dataColumn}
      wrapper={(children) => (
        <ContextMenu
          onModify={onModify}
          onDelete={onDelete}
          onMove={onMove}
          onSort={onSort}
          column={dataColumn}
        >
          {children}
        </ContextMenu>
      )}
    >
      <PropertyTitleWrap
        onClick={onClick}
        align="start"
        $pointer={pointer}
        $detailedPage={detailedPage}
        $formView={formView}
        style={{
          color: colorTextSecondary
        }}
      >
        <Tooltip title={tooltip}>
          <Flex gap="small" align="center">
            {IconElement && (
              <IconElement style={{ flexShrink: 0, fontSize: 12, color: colorTextSecondary }} />
            )}
            {title && (
              <div>
                {title}
                {dataColumn?.required &&
                  <span
                    style={{
                      marginLeft: 2,
                      fontWeight: 600,
                      color: colorErrorText
                    }}
                  >
                    *
                  </span>
                }
              </div>
            )}
            {sorted === "ascend" &&
              <SortAscendingOutlined />
            }
            {sorted === "descend" &&
              <SortDescendingOutlined />
            }
          </Flex>
        </Tooltip>
      </PropertyTitleWrap>
    </ConditionalWrapper>
  );
};

export default PropertyTitle;
