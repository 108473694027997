import { FC, memo } from "react";
import { EditableTableFormItemComponent } from "./lib";
import { TableInputNumber } from "components/DataTable/SimpleTable/FormItems/styled";
import { ColumnManagerDefaults } from "components/ColumnManagerModal/lib";
import EmptyCell from "../TableCell/Empty";
import { ReadOnlyField } from "../TableCell/styled";

const Number: FC<EditableTableFormItemComponent<number>> = (props) => {
  const { column, value, onChange, editable } = props;
  const formHandlers = { defaultValue: value, onBlur: onChange };
  const format = column.precision ?? ColumnManagerDefaults.precision;

  return (
    <>
      {editable
        ? <TableInputNumber
          readOnly={!editable}
          disabled={!editable}
          size="small"
          placeholder="Empty"
          precision={format}
          {...formHandlers}
        />
        : <>
          {value
            ? <ReadOnlyField>{value.toFixed(column.precision)}</ReadOnlyField>
            : <EmptyCell />
          }
        </>
      }
    </>
  );
};

export default memo(Number);
